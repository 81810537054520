/* Verticaltabs.css */
.tabs-container {
  display: flex;
  align-items: center;
  color: white;
  min-height: 500px;
}

.vertical-tabs {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-right: 20px;
}

.vertical-tab {
  cursor: pointer;
  padding: 15px;
  min-width: 400px;
  height: 50px;
  text-align: center;
  margin-bottom: 40px;
}
.vertical-tab p {
  text-align: left;
  font-size: 20px;
  letter-spacing: 0px;
  color: #ebebeb;
  opacity: 1;
  font-family: var(--font-family);
}
.vertical-tab-span {
  color: var(--unnamed-color-e8e8e8);
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #e8e8e8;
  opacity: 1;
  font-family: var(--font-family);
  padding-left: 60px;
  padding-right: 20px;
}
.vertical-tab.active {
  background-color: #ccc;
  background: transparent linear-gradient(90deg, #abecd6ba 0%, #fbed9600 100%)
    0% 0% no-repeat padding-box;
  opacity: 1;
}

.tab-content-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.vertical-tab-content {
  text-align: center;
  max-width: 500px;
  animation: fadeIn 0.5s ease; /* Animation for tab content */
}
.vertical-tab-content img {
  width: 150px;
  height: 150px;
}
.vertical-tab-content p {
  text-align: left;
  font-size: 20px;
  letter-spacing: 0px;
  color: #40cedd;
  opacity: 1;
  max-width: 500px;
  font-family: var(--font-family);
  padding: 20px 20px;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Add any additional styles as needed */
/* Mobile styles */
@media (max-width: 767px) {
  .tabs-container {
    flex-direction: column;
    min-height: auto;
  }

  .vertical-tabs {
    margin-right: 0;
  }

  .vertical-tab {
    min-width: auto;
    margin-bottom: 20px;
  }

  .vertical-tab p {
    font-size: 16px;
  }

  .vertical-tab-span {
    font-size: 12px;
    padding-left: 40px;
    padding-right: 10px;
  }

  .vertical-tab-content p {
    font-size: 18px;
    padding: 10px;
  }

  .vertical-tab-content img {
    width: 100px;
    height: 100px;
  }
}

/* Tablet styles */
@media (min-width: 768px) and (max-width: 1023px) {
  .vertical-tab {
    min-width: 300px;
  }

  .vertical-tab p {
    font-size: 18px;
  }

  .vertical-tab-span {
    font-size: 14px;
    padding-left: 50px;
    padding-right: 15px;
  }

  .vertical-tab-content p {
    font-size: 20px;
    padding: 15px;
  }

  .vertical-tab-content img {
    width: 120px;
    height: 120px;
  }
}
