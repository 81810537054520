.section3-container {
  display: flex;
  flex-direction: row;
  padding-top: 50px;
  position: relative;
}
.section3-image-container {
  display: flex;
  justify-content: flex-start;
  max-width: 700px;
  height: 700px;
}
.section3-image-container img {
  width: 90%;
  height: 90%;
}
.section3-text-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 25%;
  top: 60%;
  max-width: 850px;
  justify-content: center;
  align-items: center;
}
.section3-text-container p {
  text-align: center;
  font-size: 18px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  max-width: 600px;
  font-family: var(--font-family);
}
.section3-text-container-heading {
  background: transparent linear-gradient(180deg, #abecd6 0%, #fbed96 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 70px;
  font-weight: 700;
  padding-top: 10px;
  padding-bottom: 30px;
  text-align: center;
  font-family: var(--font-family);
}

.section3-text-container button {
  font-size: 20px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  background: #00bd71 0% 0% no-repeat padding-box;
  border-radius: 12px;
  opacity: 1;
  width: 200px;
  height: 50px;
  margin-top: 40px;
  border-width: 0;
}
.section-right-image {
  display: flex;
  position: absolute;
  top: 70%;
  right: 0;
  width: 250px;
}
.section-right-image img {
  width: 100%;
}
/* Mobile Version */
@media screen and (max-width: 767px) {
  .section3-container {
    flex-direction: column;
    padding-top: 30px;
  }

  .section3-image-container {
    max-width: 100%;
    height: auto;
  }

  .section3-image-container img {
    width: 100%;
    height: auto;
  }

  .section3-text-container {
    position: static;
    left: 0;
    top: 0;
    max-width: 100%;
    padding: 20px;
  }

  .section3-text-container-heading {
    font-size: 42px;
    padding-bottom: 20px;
  }

  .section3-text-container p {
    font-size: 16px;
  }

  .section3-text-container button {
    width: 160px;
    height: 40px;
    font-size: 16px;
    margin-top: 20px;
  }

  .section-right-image {
    display: none;
  }

  .section-right-image img {
    width: 100%;
    height: auto;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .section-right-image {
    display: none;
  }
}
@media (min-width: 1024px) {
  .section-right-image {
    display: flex;
    position: absolute;
    top: 80%;
    right: 0;
    width: 250px;
  }
  .section-right-image img {
    width: 90%;
  }
}
