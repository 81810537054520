/* GetStarted.css */
.get-started-container {
  max-width: 600px;
  margin: 0 auto;
  color: white;
  margin-left: 200px;
  min-width: 600px;
}
.get-started-container h2 {
  background: transparent linear-gradient(180deg, #e0c3fc 0%, #8ec5fc 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 52px;
  font-weight: bold;
  margin-bottom: 60px;
  font-family: var(--font-family);
}
.steps-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  border-top: 1px solid #a6a6a6;
  border-bottom: 1px solid #a6a6a6;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.step.active {
  transform: translateY(-5px);
  color: rgb(255, 255, 255);
}

.step-number {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.step-question {
  margin: 0;
  font-weight: bold;
  text-align: left;
  font-size: 20px;
  letter-spacing: 0px;
  color: #ebebeb;
  font-family: var(--font-family);
  padding: 20px 20px;
}

.step-answer {
  display: none;
  margin-top: 10px;
  font-size: 14px;
  text-align: left;
  font-size: 18px;
  letter-spacing: 0px;
  color: #b5b5b5;
  opacity: 1;
  font-family: var(--font-family);
  padding-left:  20px;
  padding-bottom: 10px;
}

.step.active .step-answer {
  display: block;
  opacity: 0;
  animation: fadeIn 0.3s ease 0.2s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
/* Mobile styles */
@media (max-width: 767px) {
  .get-started-container {
    max-width: 100%;
    margin-left: 20px;
    min-width: unset;
  }

  .get-started-container h2 {
    font-size: 36px;
    margin-bottom: 40px;
  }

  .step-question {
    font-size: 16px;
    padding: 10px 10px;
  }

  .step-answer {
    font-size: 14px;
    padding: 10px;
  }
}

/* Tablet styles */
@media (min-width: 768px) and (max-width: 1023px) {
  .get-started-container {
    max-width: 90%;
    margin-left: 50px;
    min-width: unset;
  }

  .get-started-container h2 {
    font-size: 42px;
    margin-bottom: 50px;
  }

  .step-question {
    font-size: 18px;
    padding: 15px 15px;
  }

  .step-answer {
    font-size: 16px;
    padding: 15px;
  }
}
