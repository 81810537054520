.faqs {
  width: 100%;

  margin: 0 auto;
  padding: 20px;
  color: white;
}

.faqs-section {
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
  max-width: 800px;
}

.faqs-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;
  text-align: left;
  font-size: 20px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  padding-bottom: 20px;
  font-family: var(--font-family);
  max-width: 100%;
}

.faqs-answer {
  padding: 10px 20px;
  text-align: left;
  font-size: 18px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  padding-bottom: 20px;
  font-family: var(--font-family);
}

.faqs-arrow-icon {
  transition: transform 0.3s;
  transform: rotate(270deg);
  margin-left: 100px;
}
.faq-arrow-img {
  height: 50px;
  width: 50px;
  
}
.faqs-arrow-icon.active {
  transform: rotate(360deg);
}

/* Mobile Styles */
@media (max-width: 767px) {
  .faqs-section {
    margin-bottom: 5px;
    padding: 10px 0;
  }

  .faqs-question {
    font-size: 18px;
    padding: 10px 15px;
  }

  .faqs-answer {
    font-size: 16px;
    padding: 10px 15px;
  }

  .faqs-arrow-icon {
    margin-left: 10px;
    height: 30px;
    width: 30px;
  }
}

/* Tablet Styles */
@media (min-width: 768px) and (max-width: 1023px) {
  .faqs-section {
    margin-bottom: 10px;
    padding: 15px 0;
  }

  .faqs-question {
    font-size: 18px;
    padding: 10px 20px;
  }

  .faqs-answer {
    font-size: 16px;
    padding: 10px 20px;
  }

  .faqs-arrow-icon {
    margin-left: 20px;
    height: 40px;
    width: 40px;
  }
}
