.container-2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 450px;
  background: linear-gradient(
    180deg,
    rgba(155, 226, 252, 1) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  border-radius: 44px;
  opacity: 1;
}
.container-3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 450px;
  background: linear-gradient(
    180deg,
    rgba(155, 181, 252, 1) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  opacity: 1;
  border-radius: 44px;
}
.container-4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 450px;
  background: linear-gradient(
    180deg,
    rgba(155, 252, 200, 1) 5%,
    rgba(0, 0, 0, 1) 100%
  );
  opacity: 1;
  border-radius: 44px;
}

/* Mobile Version */
@media screen and (max-width: 767px) {
  .container-2,
  .container-3,
  .container-4 {
    height: 250px;
    border-radius: 20px;
  }

  .hero-heading {
    font-size: 28px;
    line-height: 32px;
    padding-bottom: 20px;
  }
}

/* Styles for tablets (768px to 1023px wide) */
@media (min-width: 768px) and (max-width: 1023px) {
  .container-2,
  .container-3,
  .container-4 {
    height: 300px; /* Adjust the height for better tablet display */
    border-radius: 20px;
  }

  .hero-heading {
    font-size: 48px; /* Reduce the font size for better readability on tablets */
  }
}
