@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');
@font-face {
  font-family: "ClashDisplay-Bold";
  src: url('./Fonts/ClashDisplay-Bold.woff');
}
@font-face {
  font-family: "ClashDisplay-Regular";
  src: url('./Fonts/ClashDisplay-Regular.woff');
}
@font-face {
  font-family: "ClashDisplay-Semibold";
  src: url('./Fonts/ClashDisplay-Semibold.woff');
}
@font-face {
  font-family: "ClashDisplay-Medium";
  src: url('./Fonts/ClashDisplay-Medium.woff');
}

:root {
  --font-family: 'ClashDisplay-Regular', sans-serif;

  --gradient-text: linear-gradient(89.97deg, #AE67FA 1.84%, #F49867 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #AE67FA -13.86%, #F49867 99.55%);
  
  --color-bg: #040C18;
  --color-footer : #031B34;
  --color-blog: #042c54;
  --color-text: #81AFDD;
  --color-subtext: #FF8A71;
}