/* HorizontalTabs.css */
.horizontal-tab-list {
  display: flex;
  list-style: none;
  padding: 0;
}

.horizontal-tab-list > li {
  cursor: pointer;
  color: rgb(255, 255, 255);
  padding: 10px 20px;
  margin-right: 10px;
  border-bottom-left-radius: 0px !important;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  opacity: 1;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(50px);
  height: 40px;
  border-width: 0;
  font-family: var(--font-family);
}

.horizontal-tab-list > .react-tabs__tab--selected {
  border-bottom: 2px solid #007bff;
}

.tab-content {
  display: flex;
  align-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-top-left-radius: 0;
  border-top-right-radius: 9px;
  border-bottom-right-radius: 9px;
  border-bottom-left-radius: 9px;
  opacity: 1;
  width: 800px;
}
.tab-content select {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: bold;
  border-right: 0.5px solid #ccc;
}
.tab-content input {
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  padding: 10px;
  font-size: 16px;
  font-family: var(--font-family);
}
.location-icon {
  /* Styles for the location icon */
  height: 25px;
  width: 20px;
  margin-left: 5px;
  margin-right: 5px;
}

.dropdown {
  /* Styles for the dropdown */
  margin-right: 10px;
  padding: 5px;
  border: none;
}

.search-bar {
  /* Styles for the search bar */
  flex-grow: 1;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.search-button {
  /* Styles for the search button */
  padding: 5px 10px;
  background: transparent linear-gradient(180deg, #ffecd2 0%, #fcb69f 100%) 0%
    0% no-repeat padding-box;
  border-radius: 12px;
  opacity: 1;
  border: none;
  cursor: pointer;
  width: 150px;
  height: 50px;
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: bold;
}

.inline-text {
  display: inline;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
  padding-top: 10px;
  font-size: 12px;
  font-family: var(--font-family);
  padding-right: 10px;
}

.inline-cities {
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  color: white;
  padding-top: 10px;
}

.inline-cities span {
  margin-right: 5px;
  background: #a4a1a1 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
  font-size: 12px;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(50px);
  padding: 8px;
  font-family: var(--font-family);
  color: #dadada;
  opacity: 1;
}

/* Tablet Styles */
@media screen and (max-width: 1024px) {
  .horizontal-tab-list {
    margin: 0;
    flex-direction: column;
    
  }

  .horizontal-tab-list > li {
    margin: 5px 0;
    padding: 10px;
    font-size: 14px;
    text-align: center;
    backdrop-filter: none;
    
  }

  .tab-content {
    width: 100%;
    padding: 20px;
  }

  .tab-content select,
  .tab-content input {
    margin-bottom: 10px;
  }

  .search-button {
    width: 100px;
    height: 40px;
    font-size: 14px;
  }

  .inline-cities span {
    margin: 3px;
    padding: 5px;
    font-size: 10px;
  }
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
  .horizontal-tab-list > li {
    padding: 10px;
    font-size: 14px;
    
  }

  .tab-content {
    width: 100%;
    padding: 10px;
    flex-direction: column;
  }
  .location-icon{
    display: none;
  }

  .tab-content select,
  .tab-content input {
    width: 100%;
    margin-bottom: 10px;
  }

  .search-button {
    width: 100%;
    height: 40px;
    font-size: 14px;
  }

  .inline-text {
    display: block;
    padding: 5px 0;
  }

  .inline-cities span {
    margin: 3px;
    padding: 5px;
    font-size: 10px;
  }
}
