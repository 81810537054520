.banner-container {
  width: 100%;
  height:100vh;
  background-size: cover;
  background-image: url("../../../assets/Crowd/OIU9QM1.png");
}
.banner-text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
}
.banner__title {
  text-align: left;
  font-size: 62px;
  letter-spacing: 0px;
  color: #d6d6d6;
  opacity: 1;
  font-family: var(--font-family);
}
.banner__title2 {
  background: transparent linear-gradient(180deg, #abecd6 0%, #fbed96 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 62px;
  font-family: var(--font-family);
}
.banner-button {
  width: 200px;
  height: 50px;
  background: #ececec 0% 0% no-repeat padding-box;
  border-radius: 12px;
  opacity: 1;
  font-size: 18px;
  font-family: var(--font-family);
  border-width: 0;
  margin-top: 40px;
}


/* Mobile-friendly styles */
@media (max-width: 767px) {
  .banner-container {
    height: 80vh; /* Adjust the height for mobile */
    background-image: url("../../../assets/Crowd/Mask\ Group\ 115.png");
  }

  .banner-text-container {
    bottom: 5%; /* Adjust the position for mobile */
  }

  .banner__title {
    font-size: 40px; /* Adjust the font size for mobile */
  }

  .banner__title2 {
    font-size: 40px; /* Adjust the font size for mobile */
  }

  .banner-button {
    width: 150px; /* Adjust the button size for mobile */
    height: 40px;
    font-size: 16px; /* Adjust the font size for mobile */
  }
}

/* Tablet-friendly styles */
@media (min-width: 768px) and (max-width: 1023px) {
  .banner-container {
    height: 70vh; /* Adjust the height for tablet */
  }

  .banner-text-container {
    bottom: 8%; /* Adjust the position for tablet */
  }

  .banner__title {
    font-size: 48px; /* Adjust the font size for tablet */
  }

  .banner__title2 {
    font-size: 48px; /* Adjust the font size for tablet */
  }

  .banner-button {
    width: 180px; /* Adjust the button size for tablet */
    height: 45px;
    font-size: 16px; /* Adjust the font size for tablet */
  }
}

