.gpt3__header {
  display: flex;
  flex-direction: row;
  height: 800px;
}

.gpt3__header-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding-left: 4rem;
  margin-right: 5rem;
}

.gpt3__header-content h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 62px;
  line-height: 75px;
  letter-spacing: -0.04em;
  color: #d6d6d6;
}
.header-span-heading {
  background: transparent linear-gradient(180deg, #abecd6 0%, #fbed96 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.gpt3__header-content p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: var(--color-text);

  margin-top: 1.5rem;
}

.gpt3__header-content__input {
  width: 100%;
  margin: 2rem 0 1rem;

  display: flex;
  flex-direction: row;
}

.gpt3__header-content__input input {
  flex: 2;
  width: 100%;
  min-height: 50px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  background: var(--color-footer);
  border: 2px solid var(--color-footer);
  padding: 0 1rem;
  outline: none;
  color: #fff;

  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.gpt3__header-content__input button {
  height: 32px;
  width: 160px;
  min-height: 40px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  background: #ececec 0% 0% no-repeat padding-box;
  border-radius: 12px;
  opacity: 1;
  padding: 0 1rem;
  cursor: pointer;
  outline: none;
  color: #050609;
  border-width: 0;
}

.gpt3__header-content__people {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;

  margin-top: 2rem;
}

.gpt3__header-content__people img {
  width: 181.79px;
  height: 38px;
}

.gpt3__header-content__people p {
  margin: 0 0 0 1rem;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 12px;
  line-height: 38px;
  color: #fff;
  text-align: center;
}

.gpt3__header-image {
  flex: 0.8;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.gpt3__header-image img {
  width: 100%;
  height: 100%;
}

/* Mobile Version */
@media screen and (max-width: 650px) {
  .gpt3__header {
    height: 100%;
    padding: 10px 0;
    flex-direction: column;
  }

  .gpt3__header-content {
    align-items: center;
    padding: 0 1rem;
    margin-right: 0;
  }

  .gpt3__header-content h1 {
    font-size: 42px;
    line-height: 54px;
  }

  .gpt3__header-content p {
    font-size: 18px;
    line-height: 28px;
  }

  .gpt3__header-content__input {
    flex-direction: column;
    margin: 0;
  }

  .gpt3__header-content__input input {
    width: 100%;
    min-height: 50px;
    border-radius: 5px;
    text-align: center;
  }

  .gpt3__header-content__input button {
    width: 140px;
    height: 40px;
    font-size: 14px;
    line-height: 26px;
    margin-top: 1rem;
  }

  .gpt3__header-content__people {
    flex-direction: column;
    align-items: center;
    margin-top: 1.5rem;
  }

  .gpt3__header-content__people p {
    line-height: 20px;
    margin-bottom: 1rem;
  }

  .gpt3__header-content__people img {
    width: 150px;
    height: 31.7px;
  }

  .gpt3__header-image {
    display: block; /* Hide the header image in mobile view */
    height: auto;
  }
}

/* Further optimizations for smaller screens (e.g., smartphones) */
@media screen and (max-width: 490px) {
  .gpt3__header-content p {
    font-size: 16px;
    line-height: 22px;
  }

  .gpt3__header-content__input input,
  .gpt3__header-content__input button {
    font-size: 12px;
    line-height: 18px;
  }

  .gpt3__header-content__people img {
    width: 120px;
    height: 25px;
  }
}
/* Styles for tablets (768px to 1023px wide) */
@media (min-width: 768px) and (max-width: 1023px) {
  .gpt3__header {
    height: 600px; /* Adjust the height for better tablet display */
  }

  .gpt3__header-content {
    padding-left: 2rem; /* Adjust the padding for better spacing on tablets */
    margin-right: 2rem; /* Adjust the margin for better spacing on tablets */
  }

  .gpt3__header-content h1 {
    font-size: 48px; /* Reduce the font size for better readability on tablets */
    line-height: 60px; /* Adjust the line height for better spacing on tablets */
  }

  .gpt3__header-content p {
    font-size: 18px; /* Reduce the font size for better readability on tablets */
    line-height: 24px; /* Adjust the line height for better spacing on tablets */
    margin-top: 1rem; /* Adjust the margin for better spacing on tablets */
  }

  .gpt3__header-content__input input {
    font-size: 18px; /* Reduce the font size for better readability on tablets */
    line-height: 24px; /* Adjust the line height for better spacing on tablets */
    min-height: 40px; /* Adjust the input height for better display on tablets */
  }

  .gpt3__header-content__input button {
    font-size: 14px; /* Reduce the font size for better display on tablets */
    line-height: 24px; /* Adjust the line height for better spacing on tablets */
    height: 30px; /* Adjust the button height for better display on tablets */
  }

  .gpt3__header-content__people img {
    width: 140px; /* Adjust the image width for better display on tablets */
    height: 30px; /* Adjust the image height for better display on tablets */
  }

  .gpt3__header-content__people p {
    font-size: 10px; /* Reduce the font size for better readability on tablets */
    line-height: 30px; /* Adjust the line height for better spacing on tablets */
  }
}
