.list-hero4-container {
  background: #000000 0% 0% no-repeat padding-box;
  width: 100%;
  height: 100%;
}
.list4-hero4-container-main {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}
.list4-hero4-container-main-img {
    height: 600px;
    width: 700px;
    margin-bottom: 40px;
}
.list-hero4-container h1 {
  background: transparent linear-gradient(180deg, #abecd6 0%, #fbed96 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-size: 48px;
  font-family: var(--font-family);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding: 40px 90px;
  text-align: left;
  max-width: 1000px;
}
/* Mobile styles */
@media (max-width: 767px) {
  .list-hero4-container {
    background: #000000 0% 0% no-repeat padding-box;
    width: 100%;
  }

  .list4-hero4-container-main {
    flex-direction: column;
    align-items: center;
  }

  .list4-hero4-container-main-img {
    height: auto;
    width: 100%;
  }

  .list-hero4-container h1 {
    font-size: 32px;
    padding: 20px 40px;
    text-align: center;
  }
}

/* Tablet styles */
@media (min-width: 768px) and (max-width: 1023px) {
  .list-hero4-container h1 {
    font-size: 40px;
    padding: 30px 70px;
  }
}
