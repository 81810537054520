.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.content h1 {
  text-align: left;
  font-size: 38px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  padding: 20px 20px;
  font-family: var(--font-family);
}
.content p {
  text-align: left;
  font-size: 24px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  padding: 20px 20px;
  font-family: var(--font-family);
}
.image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
}
.image img {
  max-width: 100%;
  height: fit-content;
}
@media screen and (max-width: 768px) {
  .content h1 {
    padding: 10px 10px;
    font-size: 18px;
  }
  .content p {
    padding: 10px 10px;
    font-size: 12px;
  }
}
/* Styles for tablets (768px to 1023px wide) */
@media (min-width: 768px) and (max-width: 1023px) {
  .content h1 {
    font-size: 30px; /* Reduce the font size for better readability on tablets */
    padding: 10px; /* Adjust the padding for better spacing on tablets */
  }

  .content p {
    font-size: 20px; /* Reduce the font size for better readability on tablets */
    padding: 10px; /* Adjust the padding for better spacing on tablets */
  }

  .image img {
    max-width: 100%;
    height: auto;
  }
}
