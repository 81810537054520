.container-hero6 {
  background-image: url("../../assets/Home/Group\ 200.png");
  background-size: cover;
  position: relative;
  height: 1150px;
}


.container-hero6 h1 {
  font-size: 48px;
  font-weight: 700;
  background: transparent linear-gradient(180deg, #abecd6 0%, #fbed96 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  max-width: 800px;
  font-family: var(--font-family);
  position: absolute;
  top: 0;
  left: 0;
  padding-left: 120px;
  padding-top: 100px;
}

.container6-image {
   position: absolute;
  bottom: 8%;
  left: 40%;

}
.container6-image img{
  margin-left: 20px;
}
.container6-image-container{
  height: 100px;
  width: 100px;
}

/* Styles for tablets (768px to 1023px wide) */
@media (min-width: 768px) and (max-width: 1023px) {
  .container-hero6 h1 {
    font-size: 36px; /* Reduce the font size for better readability on tablets */
    padding-left: 40px; /* Adjust the padding for better spacing on tablets */
    padding-top: 30px; /* Adjust the padding for better spacing on tablets */
  }

  .container6-image {
    left: 20%; /* Adjust the left positioning for better display on tablets */
    bottom: 5%; /* Adjust the bottom positioning for better display on tablets */
  }
  
}
/* Styles for mobile devices (up to 767px wide) */
@media (max-width: 767px) {
  .container-hero6 h1 {
    font-size: 28px; /* Reduce the font size for better readability on mobile */
    padding-left: 20px; /* Adjust the padding for better spacing on mobile */
    padding-top: 80px; /* Adjust the padding for better spacing on mobile */
    max-width: 300px; /* Adjust the max-width to fit the screen on mobile */
    position: relative; /* Change the position to relative for better display on mobile */
    top: unset; /* Reset the top position for better display on mobile */
    left: unset; /* Reset the left position for better display on mobile */
  }

  .container6-image {
    left: 15%; /* Adjust the left positioning for better display on mobile */
    bottom: 15%; /* Adjust the bottom positioning for better display on mobile */
  }

  .container6-image img {
    width: 100px; /* Adjust the image width for better display on mobile */
    height: auto; /* Auto-adjust the image height on mobile */
    margin-left: 5px; /* Adjust the margin for better spacing on mobile */
  }
  .container-hero6 {
    background-image: url("../../assets/Home/Mobile-Screen-mockup.png");
    background-size: cover;
    background-position:  center;
    height: 100vh;

  }

  /* Additional mobile styles can be added here as needed */
}
