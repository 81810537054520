.container-blue {
  background-image: url(../../assets/List/Mask\ Group\ 39.png);
}

.container-green {
  background-image: url(../../assets/List/Mask\ Group\ 82.png);
}

.container-orange {
  background-image: url(../../assets/List/Mask\ Group\ 83.png);
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: flex-start;
}

.item {
  padding: 40px 40px;
  color: white;
  border-right: 1px solid white;
}
.card-icon {
  height: 60px;
  width: 60px;
  margin-bottom: 20px;
}
.item h2 {
  text-align: left;
  font-size: 28px;
  letter-spacing: 0px;
  color: #ebebeb;
  opacity: 1;
  font-family: var(--font-family);
  padding-bottom: 10px;
}
.item p {
  text-align: left;
  font-family: var(--font-family);
  letter-spacing: 0px;
  color: #d5d5d5;
  opacity: 1;
  font-size: 16px;
  padding-bottom: 10px;
}
/* Mobile styles */
@media (max-width: 767px) {
  .grid-container {
    grid-template-columns: 1fr;
  }

  .item {
    padding: 20px;
    border-right: none;
    border-bottom: 1px solid white;
  }

  .item:last-child {
    border-bottom: none;
  }
}

/* Tablet styles */
@media (min-width: 768px) and (max-width: 1023px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .item {
    padding: 30px;
    border-right: 1px solid white;
    border-bottom: none;
  }

  .item:nth-child(2n) {
    border-right: none;
  }

  .item:last-child {
    border-right: 1px solid white;
  }
}
