.list-banner-bg {
  background-image: url("../../../assets/List/Group\ 207.png");
  width: 100%;
  height: 100vh;
  background-size: cover;
}
.list-banner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}
.list-banner-main-heading {
  background: transparent linear-gradient(180deg, #ffecd2 0%, #fcb69f 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 60px;
  line-height: 72px;
  text-align: center;
  letter-spacing: 0px;
  font-family: var(--font-family);
  max-width: 1000px;
}
.list-banner-main-para {
  text-align: center;
  font-size: 22px;
  letter-spacing: 0px;
  color: #d5d5d5;
  font-family: var(--font-family);
  max-width: 600px;
  padding: 40px 20px;
}
.list-bannner-scroll-button {
  height: 50px;
  width: 35px;
  margin-top: 80px;
}
.scroll-text {
  font-size: 12px;
  color: #fff;
  font-family: var(--font-family);
  padding: 5px;
}
/* Mobile styles */
@media (max-width: 767px) {
  .list-banner-bg {
    background-image: url("../../../assets/List/Group 207.png");
    width: 100%;
    background-size: cover;
  }

  .list-banner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
   
  }

  .list-banner-main-heading {
    font-size: 40px;
    line-height: 48px;
    text-align: center;
    letter-spacing: 0px;
    font-family: var(--font-family);
    max-width: 90%; /* Adjust the width as needed */
  }

  .list-banner-main-para {
    text-align: center;
    font-size: 18px;
    letter-spacing: 0px;
    color: #d5d5d5;
    font-family: var(--font-family);
    max-width: 90%; /* Adjust the width as needed */
    padding: 20px;
  }

  .list-bannner-scroll-button {
    height: 40px;
    width: 30px;
    margin-top: 40px;
  }

  .scroll-text {
    font-size: 10px;
    color: #fff;
    font-family: var(--font-family);
    padding: 5px;
  }
}

/* Tablet styles */
@media (min-width: 768px) and (max-width: 1023px) {
  .list-banner-bg {
    background-image: url("../../../assets/List/Group 207.png");
    width: 100%;
    height: 60vh; /* Adjust the height as needed */
    background-size: cover;
  }

  .list-banner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 60vh; /* Adjust the height as needed */
  }

  .list-banner-main-heading {
    font-size: 50px;
    line-height: 60px;
    text-align: center;
    letter-spacing: 0px;
    font-family: var(--font-family);
    max-width: 90%; /* Adjust the width as needed */
  }

  .list-banner-main-para {
    text-align: center;
    font-size: 20px;
    letter-spacing: 0px;
    color: #d5d5d5;
    font-family: var(--font-family);
    max-width: 90%; /* Adjust the width as needed */
    padding: 30px;
  }

  .list-bannner-scroll-button {
    height: 45px;
    width: 32px;
    margin-top: 60px;
  }

  .scroll-text {
    font-size: 11px;
    color: #fff;
    font-family: var(--font-family);
    padding: 5px;
  }
}
