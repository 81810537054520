.contact-hero-bg {
  background: transparent linear-gradient(180deg, #222737 0%, #000000 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
}
.faq-heading {
  background: transparent linear-gradient(180deg, #abecd6 0%, #fbed96 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  font-size: 62px;
  font-weight: 700;
  font-family:  var(--font-family);
  padding: 60px 20px;
  text-align: center;
}
.contact-hero-main-container{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 20px;
  height: 100%;
  width: 100%;

}
.contact-hero-main-container-faq{
  padding-left: 100px;
}
.contact-hero-main-container-img{
  width: 500px;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Mobile Styles */
@media (max-width: 767px) {
  .contact-hero-main-container {
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

  .contact-hero-main-container-img {
    width: 100%;
    height: auto;
    margin-top: 30px;
  }

  .contact-hero-main-container-faq {
    padding: 0;
  }

  .faq-heading {
    font-size: 36px;
    padding: 40px 20px;
  }
}

/* Tablet Styles */
@media (min-width: 768px) and (max-width: 1023px) {
  .contact-hero-main-container {
    padding: 40px;
  }

  .contact-hero-main-container-img {
    width: 100%;
    height: auto;
    margin-top: 30px;
  }

  .contact-hero-main-container-faq {
    padding-left: 40px;
  }

  .faq-heading {
    font-size: 48px;
    padding: 60px 20px;
  }
}
