.auction-card-container {
  background: #1a1d1f 0% 0% no-repeat padding-box;
  opacity: 1;
  backdrop-filter: blur(25px);
  -webkit-backdrop-filter: blur(25px);
  border-radius: 10px;
  width: 280px;
  height: 350px;
  color: white;
  padding: 10px;
}
.auction-card-container h2 {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  font-family: var(--font-family);
}
.auction-card-container-date {
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 0.5;
  font-style: var(--font-family);
}
.auction-card-container-sub {
  display: flex;
  width: 100%;
  padding-left: 10px;
}
.auction-card-container-sub p {
  font-size: 12px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 0.5;
  font-family: var(--font-family);
  padding: 5px;
}
.auction-card-container-sub-img {
  height: 40px;
  width: 15px;
}
.auction-card-container-sub-img img {
  width: 100%;
  height: 100%;
}
.auction-card-container-sub1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
}

.auction-card-container img {
  width: 100%;
  height: 50%;
}
.auctuion-card-amount {
  text-align: left;
  font-family: var(--font-family);
  letter-spacing: 0px;
  color: #40cedd;
  opacity: 1;
  padding-left: 10px;
  font-size: 20px;
}
.auctuion-card-bidding-text {
  text-align: left;
  font-family: var(--font-family);
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 0.5;
  padding-left: 10px;
  padding-bottom: 10px;
  font-size: 12px;
  border-bottom: 2px solid #000000;
}
.auctuion-card-bidding-view-text {
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #40cedd;
  opacity: 1;
  font-family: var(--font-family);
  padding: 10px;
}

/* Tablet Styles */
@media screen and (max-width: 1024px) {
  .auction-card-container {
    width: 100%;
    height: auto;
    padding: 20px;
  }

  .auction-card-container h2 {
    font-size: 18px;
  }

  .auction-card-container-date {
    font-size: 14px;
  }

  .auction-card-container-sub {
    flex-direction: column;
    padding-left: 0;
  }

  .auction-card-container-sub p {
    font-size: 14px;
    padding: 2px 0;
  }

  .auction-card-container-sub-img {
    height: 20px;
    width: 10px;
  }

  .auction-card-container-sub1 {
    flex-direction: column;
    align-items: flex-start;
  }

  .auction-card-container img {
    height: auto;
    max-height: 200px;
  }

  .auctuion-card-amount {
    font-size: 16px;
    padding-left: 0;
  }

  .auctuion-card-bidding-text {
    font-size: 12px;
    padding-left: 0;
    padding-bottom: 5px;
    border-bottom: 1px solid #000000;
  }

  .auctuion-card-bidding-view-text {
    font-size: 14px;
    padding: 5px 0;
  }
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
  .auction-card-container {
    width: 100%;
    height: auto;
    padding: 15px;
  }

  .auction-card-container h2 {
    font-size: 16px;
  }

  .auction-card-container-date {
    font-size: 12px;
  }

  .auction-card-container-sub p {
    font-size: 12px;
    padding: 2px 0;
  }

  .auction-card-container-sub-img {
    height: 15px;
    width: 8px;
  }

  .auction-card-container img {
    max-height: 150px;
  }

  .auctuion-card-amount {
    font-size: 14px;
  }

  .auctuion-card-bidding-text {
    font-size: 10px;
    padding-bottom: 3px;
  }

  .auctuion-card-bidding-view-text {
    font-size: 12px;
    padding: 3px 0;
  }
}
