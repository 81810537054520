.buy-hero2-bg {
  background-image: url(../../../assets/Buy/Group\ 210.png);
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.buy-hero2-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.buy-hero2-text-container p {
  font-family: var(--font-family);
  font-size: 20px;
  color: #dbdbdb;
  opacity: 1;
  text-align: center;
  padding: 0 10%;
}
.buy-hero2-text-container h1 {
  font-family: var(--font-family);
  font-size: 72px;
  color: #dbdbdb;
  opacity: 1;
  text-align: center;
  padding: 0 10%;
  background: transparent linear-gradient(180deg, #abecd6 0%, #fbed96 100%) 0%
    0% no-repeat padding-box;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  max-width: 1200px;
}
.buy-hero2-tabs-container{
  position: relative;
  height: 350px;
}
.buy-hero2-tabs{
  position: absolute;
  top: -15%;
}

/* Tablet Styles */
@media screen and (max-width: 1024px) {
  .buy-hero2-bg {
    background-size: contain;
  }

  .buy-hero2-text-container p,
  .buy-hero2-text-container h1 {
    font-size: 24px;
    padding: 0 5%;
  }

  .buy-hero2-tabs-container {
    height: 250px;
  }
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
  .buy-hero2-bg {
    background-size: cover;
  }

  .buy-hero2-text-container p,
  .buy-hero2-text-container h1 {
    font-size: 20px;
    padding: 0 5%;
  }

  .buy-hero2-tabs-container {
    height: auto;
  }

  .buy-hero2-tabs {
    position: static;
    margin-top: 20px;
  }
}
