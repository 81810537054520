.cards-section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  padding: 20px;
  max-width: 500px;
}
.auction-hero-main-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.auction-hero-img {
  position: relative;
}
.auction-hero-main-img {
  height: 800px;
  width: 650px;
}
.auction-map-pin {
  position: absolute;
  top: 50%;
  left: 50%;
}
.auction-hero-img {
  position: relative;
  display: inline-block;
}

.auction-hero-main-img {
  max-width: 100%;
  height: auto;
}

.auction-map-pin {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}
.pin-image {
  height: 50px;
  width: 40px;
}
.details-container {
  position: absolute;
  top: 25%; /* Adjust this value to position the details container */
  left: 60%;
  transform: translateX(-50%);
  background: #1a1d1f 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  border-radius: 8px;
  padding: 10px;
  width: 200px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;

  width: 400px;
  height: 180px;
}

/* Add hover effect to pin image */
.auction-map-pin img:hover {
  transform: scale(1.1);
}
.map-details-container {
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 14px;
  color: #ffffff;
}
.map-details-container-sub {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 10px;
  width: 100%;
  padding: 10px;
}
.auctuion-map-bidding-text {
  text-align: left;
  font-family: var(--font-family);
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 0.5;
  padding-left: 10px;
  
  font-size: 12px;
 
}
.auction-map-container-sub {
  display: flex;
  width: 100%;
  padding-left: 20px;
}
.auction-map-container-sub-img {
  height: 25px;
  width: 15px;
  
}
.auction-map-container-sub-img img{
  width: 100%;
  height: 100%;
}
.auction-map-container-sub p {
  font-size: 12px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 0.5;
  font-family: var(--font-family);
  padding: 5px;
}
.map-sub-heading{
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  font-family: var(--font-family);
  padding-bottom: 10;
}

/* Responsive adjustments for mobile and tablet */
@media (max-width: 768px) {
  .cards-section {
    grid-template-columns: repeat(2, 1fr);
    max-width: none;
  }

  .auction-hero-main-container {
    flex-direction: column;
    align-items: center;
  }

  .auction-hero-main-img {
    height: auto;
    max-width: 100%;
  }

  .details-container {
    position: static;
    top: auto;
    left: auto;
    transform: none;
    width: 100%;
  }

  .map-details-container-sub {
    flex-direction: column;
    align-items: flex-start;
  }

  .auction-map-container-sub {
    padding-left: 0;
  }

  .auction-map-container-sub-img {
    height: auto;
  }

  .map-sub-heading {
    padding-bottom: 0;
  }
}

/* Responsive adjustments for tablet */
@media (max-width: 1024px) {
  .cards-section {
    grid-template-columns: repeat(2, 1fr);
    max-width: 100%;
  }

  .auction-hero-main-container {
    flex-direction: column;
    align-items: center;
  }

  .auction-hero-main-img {
    height: auto;
    max-width: 100%;
  }

  .details-container {
    position: static;
    top: auto;
    left: auto;
    transform: none;
    width: 100%;
  }

  .map-details-container-sub {
    flex-direction: column;
    align-items: flex-start;
  }

  .auction-map-container-sub {
    padding-left: 0;
  }

  .auction-map-container-sub-img {
    height: auto;
  }

  .map-sub-heading {
    padding-bottom: 0;
  }
}
