.list-hero3-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #000000 0% 0% no-repeat padding-box;
}
.list-hero3-container-main {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #000000 0% 0% no-repeat padding-box;
  width: 100%;
  height: 100%;
}
.list-hero3-container-main-img {
  width: 500px;
  height: 700px;
}
.list-hero3-container-main-img img {
  width: 100%;
  height: 100%;
}
/* Mobile styles */
@media (max-width: 767px) {
  .list-hero3-container-main {
    display: flex;
    flex-direction: column !important;
    align-items: center;
    background: #000000 0% 0% no-repeat padding-box;
  }

  .list-hero3-container-main-img {
    width: 100%;
    max-width: 300px;
    height: auto;
    margin-bottom: 20px;
  }
}

/* Tablet styles */
@media (min-width: 768px) and (max-width: 1023px) {
  .list-hero3-container-main-img {
    max-width: 400px;
  }
}
