/* Carousel container */
.carousel {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
  color: white;

}

/* Slides container */
.carousel-inner {
  display: flex;
  overflow: hidden;
  position: relative;
  width: 100%; /* Ensure all slides fit within the container */
}

/* Individual slide */
.carousel-slide {
  flex: 0 0 100%; /* Make each slide occupy full width */
  padding: 20px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease;
}

.carousel-slide.active {
  opacity: 1;
  visibility: visible;
}

/* Slide text style */
.carousel-slide p {
  font-size: 18px;
  line-height: 1;
  text-align: left;
  font-size: 26px;
  letter-spacing: 0px;
  color: #ffffff;
  font-family: var(--font-family);
  max-width: 700px;
  padding-left: 90px;
}

/* Carousel indicators */
.carousel-indicators {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}

/* Indicator */
.indicator {
  width: 18px;
  height: 3px; /* Height of the dash */
  background-color: #ccc;
  margin: 0 5px;
  cursor: pointer;
}

.indicator.active {
  background-color: #007bff;
}
/* Mobile styles */
@media (max-width: 767px) {
  .carousel-slide {
    padding: 10px;
  }

  .carousel-slide p {
    font-size: 20px;
    padding-left: 20px;
  }
}

/* Tablet styles */
@media (min-width: 768px) and (max-width: 1023px) {
  .carousel-slide {
    padding: 15px;
  }

  .carousel-slide p {
    font-size: 24px;
    padding-left: 50px;
  }
}
