.verticaltabs2 {
  display: flex;
  width: 100%;
  height: 350px;
}
.tab-arrow2 {
  height: 30px;
  width: 30px;
  margin-left: 50px;
}
.tabs2-container {
  display: flex;
  flex-direction: column;
  background: #1f1f1f 0% 0% no-repeat padding-box;
  opacity: 1;
  color: white;
  padding-left: 40px;
  padding-right: 40px;
  width: 500px;
}
.tabs2-container p {
  text-align: left;
  font-size: 20px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 0.7;
  font-family: var(--font-family);
  margin-left: 10px;
  border-bottom: 1px solid rgb(162, 162, 162);
  padding-top: 20px;
  padding-bottom: 10px;
}
.tab2 {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  transition: 0.3s;
  animation: fadeIn 0.5s ease-in-out;
  border-bottom: 0.7px solid rgb(185, 185, 185);
  padding-top: 50px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateX(-10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.tab2.active {
  background-color: #ddd;
}

.tab2.hovered .tab-title2 {
  -webkit-animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
}
@keyframes slide-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
}
.tab-title2 {
  margin-right: 10px;
  transition: color 0.3s;
  font-size: 20px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  font-family: var(--font-family);
}

.tab2.active .tab-title2 {
  color: #333;
}

.tab-content2 {
  flex: 1;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: left;
  font-size: 20px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  font-family: var(--font-family);
  font-weight: 400;
  max-width: 800px;
  padding-left: 180px;
}

/* Tablet Styles */
@media screen and (max-width: 1024px) {
  .verticaltabs2 {
    flex-direction: column;
    height: auto;
  }

  

  .tabs2-container {
    width: 100%;
    padding: 20px;
    text-align: left;
  }

  .tabs2-container p {
    font-size: 18px;
    padding: 10px 10px;
  }

  .tab2 {
    padding: 10px;
    padding-top: 20px;
  }

  .tab2.hovered .tab-title2 {
    animation: none;
  }

  .tab-content2 {
    padding: 10px;
    padding-left: 0;
    text-align: left;
    font-size: 18px;
    max-width: 100%;
  }
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
  .verticaltabs2 {
    flex-direction: column;
    height: auto;
  }

  .tab-arrow2 {
    display: none;
  }

  .tabs2-container {
    width: 100%;
    padding: 10px;
    text-align: left;
  }

  .tabs2-container p {
    font-size: 16px;
    padding: 8px 10px;
  }

  .tab2 {
    padding: 5px;
    padding-top: 10px;
  }

  .tab2.hovered .tab-title2 {
    animation: none;
  }

  .tab-title2 {
    font-size: 16px;
  }

  .tab-content2 {
    padding: 10px;
    text-align: left;
    font-size: 16px;
    max-width: 100%;
  }
}
