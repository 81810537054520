.section5-container {
  display: flex;
  justify-content: flex-end;
  color: white;
  background-image: url("../../assets/Home/Group\ 199.png");
  background-size: cover;
  height: 800px;
}
.section5-container h1 {
  font-size: 68px;
  font-weight: 700;
  margin-top: 60px;
  margin-bottom: 40px;
  background: transparent linear-gradient(150deg, #ffecd2 0%, #fcb69f 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  max-width: 650px;
  font-family: var(--font-family);
  line-height: 68px;
}
.section5-heading-span {
  margin-left: 160px;
}
/* Mobile Version */
@media screen and (max-width: 767px) {
  .section5-container {
    justify-content: center;
    align-items: center;
    background-image: none;
    height: auto;
    padding: 40px 20px;
  }

  .section5-container h1 {
    font-size: 42px;
    margin-top: 30px;
    margin-bottom: 20px;
    line-height: 50px;
  }

  .section5-heading-span {
    margin-left: 0;
    display: block;

  }
}
/* Mobile Version */
@media screen and (max-width: 767px) {
  .section5-container {
    justify-content: center;
    align-items: center;
    background-image: none;
    height: auto;
    padding: 40px 20px;
  }

  .section5-container h1 {
    font-size: 30px;
    margin-top: 10px;
    margin-bottom: 10px;
    line-height: 50px;
  }

  .section5-heading-span {
    margin-left: 0;
    display: block;
    text-align: center;
  }
}
