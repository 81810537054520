.buy-hero-bg {
  background: transparent linear-gradient(180deg, #222737 0%, #000000 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
  height: auto;
}
.buy-hero-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.buy-hero-container p {
  position: absolute;
  left: 5%;
  top: 80%;
  text-align: left;
  font-size: 20px;
  letter-spacing: 0px;
  color: #dbdbdb;
  opacity: 1;
  font-family: var(--font-family);
}
.buy-hero-container-img {
  max-width: 800px;
  max-height: 300px;
}
.buy-hero-container h1 {
  background: transparent linear-gradient(180deg, #abecd6 0%, #fbed96 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
  font-size: 72px;
  padding: 40px 60px;
  font-family: var(--font-family);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
}
.buy-hero-container-img2 {
  position: absolute;
  left: 42%;
  top: 49%;
  height: 150px;
  width: 150px;
}
.buy-hero-sub-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}
.buy-hero-sub-container P {
  text-align: left;
  font-family: var(--font-family);
  letter-spacing: 0px;
  color: #dbdbdb;
  opacity: 1;
  padding-bottom: 10px;
}
.buy-hero-sub-container-img {
  height: 400px;
  max-width: 600px;
  position: absolute;
  left: 60px;
  top: 100%;
}
/* Tablet Styles */
@media screen and (max-width: 1024px) {
  .buy-hero-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .buy-hero-container p {
    left: 5%;
    top: 10%;
    font-size: 16px;
    text-align: left;
  }

  .buy-hero-container h1 {
    font-size: 48px;
    padding: 20px 30px;
  }

  .buy-hero-container-img2 {
    left: 5%;
    top: 10%;
    height: 100px;
    width: 100px;
  }

  .buy-hero-sub-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .buy-hero-sub-container p {
    text-align: left;
    padding-bottom: 5px;
  }

  .buy-hero-sub-container-img {
    height: 300px;
    max-width: 400px;
    position: static;
    left: 0;
    top: 100%;
  }
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
  .buy-hero-container {
    align-items: center;
  }

  .buy-hero-container p {
    left: 5%;
    top: 30%;
    font-size: 14px;
  }

  .buy-hero-container h1 {
    font-size: 36px;
    padding: 10px 15px;
  }

  .buy-hero-container-img2 {
    display: none;
  }

  .buy-hero-sub-container {
    flex-direction: column;
    align-items: center;
  }

  .buy-hero-sub-container p {
    text-align: center;
    padding-bottom: 5px;
  }

  .buy-hero-sub-container-img {
    height: 250px;
    max-width: 350px;
    position: static;
    left: 0;
    top: 100%;
  }
  .buy-hero-container-img {
  max-width: 400px;
  max-height: 300px;
}
}
