.contact-form {
  opacity: 1;
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  border-radius: 8px;
  padding: 40px;
  color: black;
  border: none;
  align-self: flex-start;
}
.contact-header h2 {
  background: transparent linear-gradient(180deg, #ffecd2 0%, #fcb69f 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 36px;
  padding-bottom: 30px;
  font-family: var(--font-family);
}
.contact-header p {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #f2f2f2;
  opacity: 1;
  font-family: var(--font-family);
  max-width: 400px;
  padding-bottom: 20px;
}
.form-group {
  margin-bottom: 15px;
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: none;
  border-bottom: 1px solid #a9a9a9;
  outline: none;
  background-color: transparent;
  transition: border-bottom-color 0.2s ease-in-out;
  font-family: var(--font-family);
  color: white;
}
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: none;
  border-bottom: 1px solid #ddd;
  outline: none;
  font-family: var(--font-family);
  background-color: transparent;
  transition: border-bottom-color 0.2s ease-in-out;
  height: 50px;
  resize: none; /* Allow vertical resizing */
  color: white;
}

.form-group input:focus,
.form-group textarea:focus {
  border-bottom-color: #007bff;
}

.role-group {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 15px;
  color: white;
  font-family: var(--font-family);
}
.role-group input[type="radio"] {
  margin-right: 10px; /* Add a small space between the label text and radio button */
}
.role-group p {
  margin-right: 10px;
  font-family: var(--font-family);
}

.role-group label {
  margin-right: 10px;
  font-family: var(--font-family);
  padding-left: 10px;
}

.submit-button {
  background: transparent linear-gradient(180deg, #ffecd2 0%, #fcb69f 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 3px 9px #0000007d;
  border-radius: 18px;
  opacity: 1;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  width: 100%;
  height: 50px;
  font-size: 20px;
  font-family: var(--font-family);
  color: #000000;
  margin-top: 30px;
  font-weight: bold;
  
}

.submit-button:hover {
  background-color: #0056b3;
}

/* Mobile Styles */
@media (max-width: 767px) {
  .contact-form {
    padding: 20px;
  }

  .contact-header h2 {
    font-size: 28px;
    padding-bottom: 20px;
  }

  .contact-header p {
    font-size: 14px;
    max-width: 100%;
    padding-bottom: 10px;
  }

  .form-group input,
  .form-group textarea {
    font-size: 14px;
    padding: 8px;
  }

  .role-group input[type="radio"],
  .role-group label {
    font-size: 14px;
    margin-right: 5px;
    padding-left: 5px;
  }

  .submit-button {
    font-size: 18px;
    height: 40px;
    margin-top: 20px;
  }
}

/* Tablet Styles */
@media (min-width: 768px) and (max-width: 1023px) {
  .contact-form {
    padding: 30px;
  }

  .contact-header h2 {
    font-size: 32px;
    padding-bottom: 25px;
  }

  .contact-header p {
    font-size: 15px;
    max-width: 100%;
    padding-bottom: 15px;
  }

  .form-group input,
  .form-group textarea {
    font-size: 16px;
    padding: 10px;
  }

  .role-group input[type="radio"],
  .role-group label {
    font-size: 16px;
    margin-right: 8px;
    padding-left: 8px;
  }

  .submit-button {
    font-size: 19px;
    height: 45px;
    margin-top: 25px;
  }
}
