.crowd-hero3-container {
  width: 100%;
  height: auto;
  background-repeat: no-repeat;
  background-size: cover;
  background: transparent linear-gradient(180deg, #22252e 0%, #000000 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
}
.crowd-hero3-container-para {
  font-size: 16px;
  letter-spacing: 0px;
  color: #dddddd;
  opacity: 1;
  font-family: var(--font-family);
  border-bottom: 1px solid #dddddd;
  max-width: 800px;
  padding-bottom: 10px;
}
.crows-hero3-main-text-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 80px;
  color: white;
  position: relative;
}
.crows-hero3-main-text-container-item1 {
  max-width: 800px;
}
.crows-hero3-main-text-container-item1 h1 {
  background: transparent linear-gradient(180deg, #abecd6 0%, #fbed96 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  font-size: 60px;
  font-family: var(--font-family);
}
.crows-hero3-main-text-container-item2 {
  max-width: 400px;
  box-sizing: border-box;
}
.crows-hero3-main-text-container-item2 p {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  font-family: var(--font-family);
  position: absolute;
  top: 80px;
}
.with-padding {
  padding-top: 80px;
}
.crows-hero3-main-text-container-item2-sub {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  position: absolute;
  top: 160px;
}
.crowd-hero3-main-text-btn1 {
  background: #0071bd 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  width: 209px;
  height: 50px;
  text-align: center;
  font-size: 16px;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: capitalize;
  opacity: 1;
  border-width: 0;
  margin-top: 40px;
  font-family: var(--font-family);
  border-width: 0;
}
.crowd-hero3-main-text-btn2 {
  background: transparent;
  border-radius: 10px;
  opacity: 1;
  width: 209px;
  height: 50px;
  text-align: center;
  font-size: 16px;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: capitalize;
  opacity: 1;
  border-width: 0;
  margin-top: 40px;
  font-family: var(--font-family);
}
.crows-hero3-main-container2 {
  margin-top: 150px;
  position: relative;
}
.crowd3-main-container-image {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.crows-hero3-main-img-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 40px;
}
.crows-hero3-main-img-container img {
  max-width: 800px;
}
.crows-hero3-main-text-container2 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 20px;
  background: transparent linear-gradient(180deg, #e2ebf0 0%, #a6bcca 100%) 0%
    0% no-repeat padding-box;
  border-radius: 12px;
  opacity: 1;
  width: 400px;
  height: 180px;
  position: absolute;
  right: 50px;
  top: 0;
  padding: 20px 20px;
}
.crows-hero3-main-text-container2-para {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-family: var(--font-family);
}
.crows-hero3-main-text-container2-heading {
  text-align: left;
  font-size: 32px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-family: var(--font-family);
  margin-top: 70px;
}
.crows-hero3-main-text-container2-heading2 {
  text-align: left;
  font-size: 32px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-family: var(--font-family);
  margin-top: 50px;
}
.crows-hero3-main-text-container2-para2 {
  position: absolute;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-family: var(--font-family);
  right: 10px;
  bottom: 30px;
}
.crows-hero3-main-text-container3 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 20px;
  background: #d6ec70 0% 0% no-repeat padding-box;
  border-radius: 12px;
  opacity: 1;
  width: 400px;
  height: 180px;
  position: absolute;
  right: 50px;
  top: 220px;
  padding: 20px 20px;
}

/* Mobile-friendly styles */
@media (max-width: 767px) {
  .crowd-hero3-container {
    background: transparent linear-gradient(180deg, #22252e 0%, #000000 100%) 0% 0% no-repeat padding-box;
    height: auto;
    padding-bottom: 30px;
  }

  .crowd-hero3-container-para {
    font-size: 14px;
    padding-bottom: 20px;
  }

  .crows-hero3-main-text-container {
    flex-direction: column;
    gap: 30px;
  }

  .crows-hero3-main-text-container-item1 h1 {
    font-size: 36px;
    max-width: 100%;
  }

  .crows-hero3-main-text-container-item2 p {
    position: static;
    top: 0;
  }

  .crows-hero3-main-text-container-item2-sub {
    flex-direction: column;
    gap: 10px;
    position: static;
    top: 0;
  }

  .crowd-hero3-main-text-btn1,
  .crowd-hero3-main-text-btn2 {
    width: 100%;
    margin-top: 20px;
  }

  .crows-hero3-main-container2 {
    margin-top: 50px;
  }

  .crows-hero3-main-img-container {
    flex-direction: column;
    gap: 20px;
  }

  .crows-hero3-main-img-container img {
    max-width: 100%;
  }

  .crows-hero3-main-text-container2 {
    width: 100%;
    position: static;
    right: 0;
    top: 0;
    padding: 10px 10px;
    margin-bottom: 20px;
  }

  .crows-hero3-main-text-container2-heading,
  .crows-hero3-main-text-container2-heading2 {
    font-size: 24px;
  }

  .crows-hero3-main-text-container2-para2 {
    font-size: 12px;
    position: static;
    right: 0;
    bottom: 0;
    padding-bottom: 5px;
  }

  .crows-hero3-main-text-container3 {
    width: 100%;
    position: static;
    right: 0;
    top: 0;
    padding: 10px 10px;
    margin-bottom: 20px;
  }
}

/* Tablet-friendly styles */
@media (min-width: 768px) and (max-width: 1023px) {
  .crowd-hero3-container {
    background: transparent linear-gradient(180deg, #22252e 0%, #000000 100%) 0% 0% no-repeat padding-box;
    height: auto;
    padding-bottom: 30px;
  }

  .crowd-hero3-container-para {
    font-size: 14px;
    padding-bottom: 20px;
  }

  .crows-hero3-main-text-container {
    flex-direction: column;
    gap: 30px;
  }

  .crows-hero3-main-text-container-item1 h1 {
    font-size: 36px;
    max-width: 100%;
  }

  .crows-hero3-main-text-container-item2 p {
    position: static;
    top: 0;
  }

  .crows-hero3-main-text-container-item2-sub {
    flex-direction: column;
    gap: 10px;
    position: static;
    top: 0;
  }

  .crowd-hero3-main-text-btn1,
  .crowd-hero3-main-text-btn2 {
    width: 100%;
    margin-top: 20px;
  }

  .crows-hero3-main-container2 {
    margin-top: 50px;
  }

  .crows-hero3-main-img-container {
    flex-direction: column;
    gap: 20px;
  }

  .crows-hero3-main-img-container img {
    max-width: 100%;
  }

  .crows-hero3-main-text-container2 {
    width: 100%;
    position: static;
    right: 0;
    top: 0;
    padding: 10px 10px;
    margin-bottom: 20px;
  }

  .crows-hero3-main-text-container2-heading,
  .crows-hero3-main-text-container2-heading2 {
    font-size: 32px;
  }

 

  .crows-hero3-main-text-container3 {
    width: 100%;
    position: static;
    right: 0;
    top: 0;
    padding: 10px 10px;
    margin-bottom: 20px;
  }
}
@media (min-width: 1024px) and (max-width: 1279px) {
  /* Common styles for all screen sizes */


  .crowd-hero3-container {
    padding: 40px;
  }

  .crows-hero3-main-text-container {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 80px;
  }

  .crows-hero3-main-text-container-item1 h1 {
    font-size: 70px;
  }

  .crows-hero3-main-text-container-item2 {
    max-width: 500px;
    position: relative;
    top: 0;
    text-align: left;
  }

  .crows-hero3-main-text-container-item2 p {
    font-size: 18px;
  }

  .crows-hero3-main-text-container-item2-sub {
    flex-direction: row;
    gap: 20px;
    position: static;
    top: 0;
  }

  .crowd-hero3-main-text-btn1,
  .crowd-hero3-main-text-btn2 {
    width: 250px;
    height: 60px;
    font-size: 20px;
  }

  .crows-hero3-main-container2 {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 150px;
  }

  .crows-hero3-main-img-container {
    flex-direction: row;
    gap: 40px;
  }

  .crows-hero3-main-img-container img {
    max-width: 100%;
    height: 500px;
  }

  .crows-hero3-main-text-container2,
  .crows-hero3-main-text-container3 {
    max-width: 400px;
  }
}



  

