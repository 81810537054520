.Auction-banner-container-bg {
  background-image: url(../../../assets/Auction/Group\ 195.png);
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
}
.Auction-banner-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.Auction-banner-main-heading {
  background: transparent linear-gradient(180deg, #ffecd2 0%, #fcb69f 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
  font-size: 54px;
  line-height: 60px;
  max-width: 700px;
  padding-bottom: 20px;
  text-align: center;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: var(--font-family);
}


/* Tablet Styles */
@media screen and (max-width: 1024px) {
  .Auction-banner-main-heading {
    font-size: 36px;
    line-height: 40px;
    max-width: 100%;
    padding-bottom: 10px;
  }
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
  .Auction-banner-container-bg {
    background-size: cover;
  }

  .Auction-banner-container {
    height: auto;
    padding: 20px;
  }

  .Auction-banner-main-heading {
    font-size: 24px;
    line-height: 28px;
    max-width: 100%;
    padding-bottom: 10px;
  }
}
