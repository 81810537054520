.list-hero2-bg {
  background: #000000 0% 0% no-repeat padding-box;
  opacity: 1;
}
.list-hero2-main-text-container {
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.list-hero2-main-text-container h1 {
  background: transparent linear-gradient(180deg, #abecd6 0%, #fbed96 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 60px;
  font-family: var(--font-family);
  font-weight: 700;
  text-align: center;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
.list-hero2-main-text-container p {
  text-align: center;
  font-size: 20px;
  letter-spacing: 0px;
  color: #d5d5d5;
  opacity: 1;
  max-width: 600px;
  padding: 20px 20px;
  font-family: var(--font-family);
}
/* Mobile styles */
@media (max-width: 767px) {
  .list-hero2-bg {
    background: #000000 0% 0% no-repeat padding-box;
    opacity: 1;
  }

  .list-hero2-main-text-container {
    padding-top: 50px;
  }

  .list-hero2-main-text-container h1 {
    font-size: 40px;
    letter-spacing: 0px;
    color: #ffffff;
  }

  .list-hero2-main-text-container p {
    font-size: 18px;
    letter-spacing: 0px;
    color: #d5d5d5;
    max-width: 600px;
    padding: 10px;
  }
}

/* Tablet styles */
@media (min-width: 768px) and (max-width: 1023px) {
  .list-hero2-main-text-container {
    padding-top: 70px;
  }

  .list-hero2-main-text-container h1 {
    font-size: 50px;
  }

  .list-hero2-main-text-container p {
    font-size: 18px;
    padding: 15px;
  }
}
