/* Styles for carousel container */
.HorizontalCarousel-carousel {
  position: relative;
  overflow: hidden;
  width: 100%;
}

/* Styles for carousel slides */
.HorizontalCarousel-slide {
  display: none;
  width: 100%;
  padding: 40px;
  background: #272727 0% 0% no-repeat padding-box;
  border-radius: 24px;
  opacity: 1;
  width: 500px;
  height: auto;
}

.HorizontalCarousel-slide.active {
  display: block;
}

/* Example styles for carousel indicators */
.HorizontalCarousel-indicator {
  width: 10px;
  height: 10px;
  background-color: #ccc;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
}

.HorizontalCarousel-indicator.active {
  background-color: #333;
}

.horizontal-card-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.horizontal-card-container-sub1 {
  display: flex;
  flex-direction: row;
}
.horizontal-card-container-sub1 img {
  height: 30px;
  width: 30px;
  margin-right: 10px;
}
.horizontal-card-container-sub1 p {
  font: normal normal medium 23px/29px Clash Display;
  letter-spacing: 0px;
  color: #d6d6d6;
  opacity: 1;
  font-family: var(--font-family);
  padding-top: 8px;
}
.horizontal-card-container-sub2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.amount-text {
  text-align: left;
  font-size: 28px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  font-family: var(--font-family);
  padding-top: 30px;
}
.horizontal-card-container-sub2 h2 {
  text-align: left;
  font-size: 32px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  font-family: var(--font-family);
}
.horizontal-card-container-sub2 p {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  font-family: var(--font-family);
  padding-top: 30px;
}

.horizontal-card-container-img img {
  height: 40px;
  width: 40px;
}
/* Indicators styles */
.HorizontalCarousel-indicators {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.HorizontalCarousel-indicators button {
  width: 16px;
  height: 5px;
  margin: 0 5px;
  border: 1px solid #bbb;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease;
}

.HorizontalCarousel-indicators button.active {
  background-color: #bbb;
}

/* Controls styles */
.sti_container {
  position: relative;
}

.btn {
  position: relative;
  display: inline-block;
  padding: 15px;
  background: transparent linear-gradient(180deg, #abecd6 0%, #fbed96 100%) 0%
    0% no-repeat padding-box;
  border-radius: 18px;
  opacity: 1;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  color: #fff;
  border-radius: 25px;
  -webkit-transition: width 0.5s;
  transition: width 0.5s;
}
.btn .btn-text {
  max-width: 0;
  display: inline-block;
  -webkit-transition: color 0.25s 1.5s, max-width 2s;
  transition: color 0.25s 1.5s, max-width 2s;
  vertical-align: top;
  white-space: nowrap;
  overflow: hidden;
  color: blue;
  font-family: var(--font-family);
  font-size: 20px;
  
}

.btn:hover .btn-text {
  max-width: 300px;
  color: rgb(9, 9, 9);
  padding: 10px;
}

/* Tablet Styles */
@media screen and (max-width: 1024px) {
  .HorizontalCarousel-slide {
    padding: 20px;
    width: 80%;
    margin: 0 auto;
    box-sizing: border-box;
  }

  .horizontal-card-container-sub1 p {
    font-size: 18px;
  }

  .amount-text {
    font-size: 24px;
  }

  .horizontal-card-container-sub2 h2 {
    font-size: 28px;
  }

  .horizontal-card-container-sub2 p {
    font-size: 12px;
    padding-top: 20px;
  }

  .HorizontalCarousel-indicators button {
    width: 12px;
    height: 4px;
  }

  .btn {
    padding: 10px;
  }

  .btn .btn-text {
    font-size: 16px;
  }
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
  .HorizontalCarousel-slide {
    padding: 10px;
    width: 350px;
    margin: 0 auto;
    box-sizing: border-box;
  }

  .horizontal-card-container-sub1 img {
    height: 25px;
    width: 25px;
    margin-right: 0;
    margin-bottom: 5px;
  }

  .horizontal-card-container-sub1 p {
    font-size: 16px;
    padding-top: 5px;
  }

  

  .amount-text {
    font-size: 20px;
    padding-top: 20px;
  }

  .horizontal-card-container-sub2 h2 {
    font-size: 24px;
  }

  .horizontal-card-container-sub2 p {
    font-size: 10px;
    padding-top: 15px;
  }

  .HorizontalCarousel-indicators button {
    width: 10px;
    height: 3px;
    margin: 0 3px;
  }

  .btn {
    padding: 8px;
  }

  .btn .btn-text {
    font-size: 14px;
  }
}
