.crowd-hero2-container {
  background-image: url("../../../assets/Crowd/Group\ 202.png");
  width: 100%;
  height: 800px;
  background-repeat: no-repeat;
  background-size: cover;
}
.crowd-hero2-container-main-heading {
  background: transparent linear-gradient(180deg, #ffecd2 0%, #fcb69f 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 32px;
  max-width: 500px;
  font-family: var(--font-family);
}

/* CSS for TimelineContainer component */
.timeline-container {
  position: relative;
  max-width: 600px;
  margin-top: 150px;
}

.timeline-container h1 {
  font-size: 20px;
  margin-bottom: 10px;
  color: white;
  font-family: var(--font-family);
}
.timeline-container img {
  width: 40px;
  height: 40px;
  margin-bottom: 20px;
}

.timeline-container p {
  font-size: 12px;
  margin-bottom: 10px;
  color: white;
  font-weight: 400;
  font-family: var(--font-family);
}

.timeline-container button {
  font-size: 18px;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  background: transparent linear-gradient(180deg, #e2ebf0 0%, #a6bcca 100%) 0%
    0% no-repeat padding-box;
  border-radius: 12px;
  opacity: 1;
  width: 200px;
  height: 50px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-family: var(--font-family);
}

/* Specific styles for each container class */
.crwod-hero2-container1 {
  border-right: 1px solid #ffffff;
  border-bottom: 1px solid #ffffff;
  position: absolute;
  max-width: 300px;
  padding: 20px 40px;
}

.crwod-hero2-container2 {
  border-bottom: 1px solid #ffffff;
  position: absolute;
  left: 300px;
  top: 50px;
  padding: 20px 40px;
}

/* Add a specific class for the second container */
.container2 .container2-heading {
  margin-top: 40px;
}

.crwod-hero2-container3 {
  border-right: 1px solid #ffffff;
  position: absolute;
  top: 190px;
  max-width: 300px;
  padding: 20px 40px;
}

.crwod-hero2-container4 {
  border-right: 1px solid #000000;
  position: absolute;
  top: 320px;
  right: 10%;
  max-width: 300px;
}
/* Mobile-friendly styles */
@media (max-width: 767px) {
  .crowd-hero2-container {
    height: auto; /* Adjust the height for mobile */
    background-size: cover; /* Adjust the background size for mobile */
  }

  .crowd-hero2-container-main-heading {
    font-size: 20px; /* Adjust the font size for mobile */
    max-width: 200px; /* Adjust the max width for mobile */
  }

  .timeline-container {
    margin-top: 50px; /* Adjust the margin for mobile */
    display: flex;
    flex-direction: column; /* Set the container direction to column */
  
  }

  .timeline-container h1 {
    font-size: 16px; /* Adjust the font size for mobile */
  }

  .timeline-container img {
    width: 30px; /* Adjust the image size for mobile */
    height: 30px;
  }

  .timeline-container p {
    font-size: 12px; /* Adjust the font size for mobile */
  }

  .timeline-container button {
    font-size: 14px; /* Adjust the font size for mobile */
    width: 150px; /* Adjust the button size for mobile */
    height: 40px;
  }

  .crwod-hero2-container1,
  .crwod-hero2-container2,
  .crwod-hero2-container3,
  .crwod-hero2-container4 {
    position: static;
    border: none;
  }

  .crwod-hero2-container2 {
    top: 350px; /* Adjust the position for mobile */
  }

  .crwod-hero2-container3 {
    top: 550px; /* Adjust the position for mobile */
  }

  .crwod-hero2-container4 {
    top: 750px; /* Adjust the position for mobile */
  }
}



