.container-hero2 {
  background-image: url("../../assets/Home/Group\ 198.png");
  height: 800px;
  width: 100%;
  background-size: cover;
  display: flex;
}
.container-hero2-text {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  flex: 0.7;
  padding-top: 100px;
}
.container-hero2-text-heaing {
  font-family: var(--font-family);
  font-size: 38px;
  font-weight: 500;
  color: #ffdda8;
  margin: 0;
  padding: 0;
}
.container-hero2-text-gradient {
  background: #f7d297;
  background: linear-gradient(to right, #f7d297 0%, #e4a53f 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 50px;
  font-family: var(--font-family);
}
.container-hero2-text p {
  max-width: 600px;
  font-size: 18px;
  color: #ffdda8;
  padding-top: 50px;
  line-height: 38px;
  font-family: var(--font-family);
}
.container-hero2-text button {
  background: #e8e8e8 0% 0% no-repeat padding-box;
  border-radius: 12px;
  opacity: 1;
  width: 150px;
  height: 50px;
  border: none;
  margin-top: 25px;
  font-size: 16px;
}
/* Mobile Version */
@media (max-width: 767px) {
  .container-hero2 {
    height: auto; /* Adjust the height as per your design requirements */
    background-size: cover;
    width: 100%;
    background-image: url("../../assets/Home/Group\ 213.png");
  }

  .container-hero2-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding-top: 50px;
    flex: 1;
  }

  .container-hero2-text-heaing {
    font-size: 28px; /* Adjust the font size as per your design requirements */
    margin: 0;
    padding: 0;
  }

  .container-hero2-text-gradient {
    background: #f7d297;
    background: linear-gradient(to right, #f7d297 0%, #e4a53f 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 36px; /* Adjust the font size as per your design requirements */
  }

  .container-hero2-text p {
    max-width: 400px; /* Adjust the max-width as per your design requirements */
    font-size: 16px; /* Adjust the font size as per your design requirements */
    padding-top: 30px;
    line-height: 28px; /* Adjust the line-height as per your design requirements */
  }

  .container-hero2-text button {
    width: 120px; /* Adjust the button width as per your design requirements */
    height: 40px; /* Adjust the button height as per your design requirements */
    font-size: 14px; /* Adjust the font size as per your design requirements */
  }
}
/* Styles for tablets (768px to 1023px wide) */
@media (min-width: 768px) and (max-width: 1023px) {
  .container-hero2 {
    height: 600px; /* Adjust the height for better tablet display */
  }

  .container-hero2-text {
    flex: 0.8; /* Adjust the flex value for better spacing on tablets */
    padding-top: 50px; /* Adjust the padding for better spacing on tablets */
    align-items: center; /* Center the text content on tablets */
  }

  .container-hero2-text-heaing {
    font-size: 32px; /* Reduce the font size for better readability on tablets */
  }

  .container-hero2-text-gradient {
    font-size: 40px; /* Reduce the font size for better readability on tablets */
  }

  .container-hero2-text p {
    font-size: 16px; /* Reduce the font size for better readability on tablets */
    line-height: 28px; /* Adjust the line height for better spacing on tablets */
    padding-top: 30px; /* Adjust the padding for better spacing on tablets */
    text-align: center; /* Center the text content on tablets */
  }

  .container-hero2-text button {
    width: 120px; /* Adjust the button width for better display on tablets */
    height: 40px; /* Adjust the button height for better display on tablets */
    font-size: 14px; /* Reduce the font size for better display on tablets */
    margin-top: 20px; /* Adjust the margin for better spacing on tablets */
  }
}
