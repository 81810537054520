.hero {
  display: none;
}
.vertical-slider {
  display: block;
}


/* Media query for mobile */
@media (max-width: 767px) {
  .hero {
    display: block;
  }
  .vertical-slider {
    display: none;
  }
}
