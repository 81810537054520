/* DynamicColumns.css */
.section4-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  color: white;
  margin-top: 150px;
}
.container1 {
  background: transparent linear-gradient(180deg, #9fe5ff 0%, #0a0c11 100%) 0%
    0% no-repeat padding-box;
  border-radius: 44px;
  opacity: 1;
  height: 300px;
  width: 300px;
}
.container2 {
  background: transparent linear-gradient(180deg, #88a3eb 0%, #0a0c11 100%) 0%
    0% no-repeat padding-box;
  border-radius: 44px;
  opacity: 1;
  height: 300px;
  width: 300px;
}
.container3 {
  background: transparent linear-gradient(180deg, #7cf8b6 0%, #0a0c11 100%) 0%
    0% no-repeat padding-box;
  border-radius: 44px;
  opacity: 1;
  height: 300px;
  width: 300px;
}
.container4 {
  background: transparent linear-gradient(180deg, #e0c9da 0%, #0a0c11 100%) 0%
    0% no-repeat padding-box;
  border-radius: 44px;
  opacity: 1;
  height: 300px;
  width: 300px;
}
.row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
  align-items: center;
}
.row h1{
  font-size: 28px;
  font-family: var(--font-family);
  color: #F6F6F6;
  font-weight: 500;
  padding: 40px 20px;
}
.column {
  flex: 1;
  width: 50%;
  max-width: 25%;
  padding: 1rem;
  box-sizing: border-box;
  text-align: center;
}
.section4-heading {
  background: #e0c3fc;
  background: transparent linear-gradient(180deg, #e0c3fc 0%, #8ec5fc 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 68px;
  font-family: var(--font-family);
  font-weight: 700;
  text-align: center;
  line-height: 78px;
  margin-bottom: 60px;
}
/* Mobile Version */
@media screen and (max-width: 767px) {
  .section4-container {
    margin-top: 10px;
  }

  .row {
    flex-direction: column;
    gap: 1rem;
  }

  .row h1 {
    font-size: 24px;
    padding: 20px 10px;
  }

  .column {
    flex: 1;
    width: 100%;
    max-width: 100%;
  }

  .container1,
  .container2,
  .container3,
  .container4 {
    height: 200px;
    width: 100%;
  }

  .section4-heading {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 30px;
    text-align: left;
  }
}

/* Styles for tablets (768px to 1023px wide) */
@media (min-width: 768px) and (max-width: 1023px) {
  .row {
    gap: 1rem; /* Reduce the gap between columns for better spacing on tablets */
  
  }

  .row h1 {
    font-size: 24px; /* Reduce the font size for better readability on tablets */
    padding: 20px 10px; /* Adjust the padding for better spacing on tablets */
  }

  .column {
    max-width: 40%; /* Adjust the maximum width for better display on tablets */
  }

  .section4-heading {
    font-size: 48px; /* Reduce the font size for better readability on tablets */
    line-height: 58px; /* Adjust the line height for better spacing on tablets */
    margin-bottom: 40px; /* Adjust the margin for better spacing on tablets */
  }
}
