.faq-item {
  max-width: 750px;
  position: relative;
}

.question {
  font-weight: bold;
  cursor: pointer;
  position: relative;
  padding-bottom: 30px;
  padding-top: 30px;
  font-family: var(--font-family);
  font-size: 22px;
  font-weight: 400;
}

.arrow {
  height: 36px;
  display: inline-block;
  transition: transform 0.3s;
  position: absolute;
  right: 0;
  top: 40%;
  padding-top: 10px;
  transform: translateY(-50%);
}

.arrow.open {
  transform: translateY(-50%) rotate(180deg);
}

.answer {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: max-height 0.3s, opacity 0.3s;
}

.faq-item.open .answer {
  max-height: 100px; /* Change to a suitable value for your answer content */
  opacity: 1;
}
/* Mobile Version */
@media screen and (max-width: 767px) {
  .faq-item {
    max-width: 100%;
  }

  .question {
    font-size: 16px;
    padding-bottom: 15px;
    padding-top: 15px;
    max-width: 100%;
  }
  .question p{
    margin-right: 10px;
  }
  
  .arrow {
    height: 24px;
    top: 50%;
    padding-top: 5px;
    float: right;
  }

  .arrow.open {
    transform: translateY(-50%) rotate(180deg);
  }

  .answer {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: max-height 0.3s, opacity 0.3s;
  }

  .faq-item.open .answer {
    max-height: 200px; /* Change to a suitable value for your answer content */
    opacity: 1;
  }
}
