.contact-bg {
  background-image: url(../../../assets/Contact/Group\ 111.png);
  height: 100vh;
  width: 100%;
  background-size: cover;
}
.contact-main-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
}

.contact-section {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  color: #ffffff;
  opacity: 1;
}

.contact-details {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}

.contact-icon img {
  width: 25px;
  height:25px;
}

.contact-info p {
  color: #ffffff;
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  font-family: var(--font-family);
  max-width: 500px;
}
.contact-heading {
  text-align: left;
  font: normal normal medium 44px/83px Clash Display;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  font-family: var(--font-family);
  padding-bottom: 50px;
}
/* Mobile Styles */
@media (max-width: 767px) {
  .contact-bg {
    height: auto;
    background-size: cover;
  }
  
  .contact-main-container {
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

  .contact-section {
    height: auto;
    padding: 20px;
    align-items: center;
    text-align: center;
  }

  .contact-heading {
    font-size: 24px;
    padding-bottom: 30px;
  }

  .contact-details {
    flex-direction: column;
    gap: 10px;
  }

  .contact-icon img {
    width: 20px;
    height: 20px;
  }

  .contact-info p {
    font-size: 14px;
    max-width: 100%;
  }
}

/* Tablet Styles */
@media (min-width: 768px) and (max-width: 1023px) {
  .contact-bg {
    height: auto;
    background-size: cover;
  }
  
  .contact-main-container {
    padding: 40px;
  }

  .contact-section {
    height: auto;
    padding: 40px;
  }

  .contact-heading {
    font-size: 36px;
    padding-bottom: 40px;
  }

  .contact-details {
    gap: 15px;
  }

  .contact-icon img {
    width: 25px;
    height: 25px;
  }

  .contact-info p {
    font-size: 16px;
    max-width: 500px;
  }
}
