.crowd-hero4-container {
  background: transparent linear-gradient(180deg, #22252e 0%, #000000 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
  height: auto;
}
.crowd-hero4-container-main-heading {
  background: transparent linear-gradient(104deg, #ffecd2 0%, #fcb69f 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
  text-align: center;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 40px;
  font-family: var(--font-family);
}
.crowd-hero4-container-main-para {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  font-family: var(--font-family);
  max-width: 500px;
  padding-top: 40px;
}
.crowd-hero4-container-main-img {
  width: 100%;
  height: auto;

  display: flex;
  justify-content: center;
  align-items: center;
}
.crowd-hero4-container-main-img img {
  height: 500px;
  width: 500px;
}
.crowd-hero4-container-sub {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
.crowd-hero4-container-sub-text {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  max-width: 400px;
  font-family: var(--font-family);
}
.crowd-hero4-container-sub-button {
  background: transparent linear-gradient(180deg, #ffecd2 0%, #fcb69f 100%) 0%
    0% no-repeat padding-box;
  border-radius: 12px;
  opacity: 1;
  font-size: 18px ;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  border-width: 0;
  width: 200px;
  height: 50px;
}
/* Mobile-friendly styles */
@media (max-width: 767px) {
  .crowd-hero4-container-main-heading {
    font-size: 32px;
  }

  .crowd-hero4-container-main-para {
    font-size: 16px;
    max-width: 100%;
  }

  .crowd-hero4-container-main-img img {
    height: auto;
    width: 100%;
    max-height: 300px;
    max-width: 300px;
  }

  .crowd-hero4-container-sub {
    flex-direction: column;
    align-items: center;
  }

  .crowd-hero4-container-sub-text {
    max-width: 100%;
    margin-top: 20px;
  }

  .crowd-hero4-container-sub-button {
    margin-top: 20px;
    width: 150px;
    
  }
}

/* Tablet-friendly styles */
@media (min-width: 768px) and (max-width: 1023px) {
  .crowd-hero4-container-main-heading {
    font-size: 36px;
  }

  .crowd-hero4-container-main-para {
    font-size: 16px;
    max-width: 500px;
  }

  .crowd-hero4-container-main-img img {
    height: 500px;
    width: 500px;
    max-height: 500px;
    max-width: 500px;
  }

  .crowd-hero4-container-sub {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .crowd-hero4-container-sub-text {
    max-width: 400px;
    margin-top: 0;
  }

  .crowd-hero4-container-sub-button {
    width: 200px;
    margin-top: 0;
  }
}
/* MacBook Air styles */
@media (min-width: 1024px) and (max-width: 1279px) {
  .crowd-hero4-container-main-heading {
    font-size: 40px;
  }

  .crowd-hero4-container-main-para {
    font-size: 18px;
    max-width: 500px;
  }

  .crowd-hero4-container-main-img img {
    height: 600px;
    width: 600px;
    max-height: 600px;
    max-width: 600px;
  }

  .crowd-hero4-container-sub-button {
    width: 250px;
    margin-top: 0;
  }
}