.insight-heading {
  background: transparent linear-gradient(180deg, #e0c3fc 0%, #8ec5fc 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 65px;
  font-weight: 700;
  margin-bottom: 20px;
  font-family: var(--font-family);
  text-align: center;
}

.insight-section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.insight-card {
  display: flex;
  flex-direction: column;
  padding: 20px;
  color: white;
  margin-right: 40px;
}

.insight-image img {
  width: 100%;
  height: auto;
}

.insight-content {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
}

.insight-date {
  font-size: 14px;
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #40cedd;
  opacity: 1;
  font-family: var(--font-family);
  padding-top: 10px;
}

.insight-description {
  margin-top: 5px;
  text-align: left;
  font-size: 18px;
  letter-spacing: 0px;
  color: #f5f5f5;
  opacity: 1;
  font-family: var(--font-family);
  max-width: 400px;
}

.insight-divider {
  margin: 15px 0;
  border: none;
  border-top: 1px solid #ddd;
}

.read-more-link {
  color: blue;
  text-decoration: underline;
}

.read-more-link:hover {
  text-decoration: none;
}
.insight-footer {
  text-align: left;
  letter-spacing: 0px;
  color: #dbdbdb;
  opacity: 1;
  font-family: var(--font-family);
  font-size: 14px;
}
.insight-footer a {
  text-align: left;
  letter-spacing: 0px;
  color: #dbdbdb;
  opacity: 1;
  font-family: var(--font-family);
  font-size: 15px;
  text-decoration: none;
  font-weight: bold;
  padding-left: 5px;
}

/* Tablet Styles */
@media screen and (max-width: 1024px) {
  .insight-heading {
    font-size: 48px;
    margin-bottom: 10px;
    text-align: center;
  }

  .insight-section {
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  }

  .insight-card {
    padding: 10px;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .insight-image img {
    width: 100%;
    height: auto;
  }

  .insight-content {
    padding: 0;
  }

  .insight-date {
    font-size: 12px;
    padding-top: 5px;
  }

  .insight-description {
    font-size: 16px;
    max-width: 100%;
  }

  .insight-footer {
    font-size: 12px;
  }

  .insight-footer a {
    font-size: 14px;
    padding-left: 0;
  }
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
  .insight-heading {
    font-size: 36px;
    margin-bottom: 10px;
    text-align: center;
  }

  .insight-section {
    grid-template-columns: repeat(1, 1fr);
    gap: 15px;
  }

  .insight-card {
    padding: 10px;
    margin-right: 0;
    margin-bottom: 15px;
  }

  .insight-image img {
    width: 100%;
    height: auto;
  }

  .insight-content {
    padding: 0;
  }

  .insight-date {
    font-size: 12px;
    padding-top: 5px;
  }

  .insight-description {
    font-size: 14px;
    max-width: 100%;
  }

  .insight-footer {
    font-size: 12px;
  }

  .insight-footer a {
    font-size: 12px;
    padding-left: 0;
  }
}
