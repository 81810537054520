.hero-heading {
  text-align: center;
  font-size: 78px;
  line-height: 86px;
  letter-spacing: 0px;
  color: #d6d6d6;
  opacity: 1;
  font-family: var(--font-family);
}
.hero-heading span {
  background: #abecd6;
  background: linear-gradient(to right, #abecd6 15%, #fbed96 54%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.vertical-slider-container {
  align-items: center;
  block-size: auto;
  display: flex;
  flex-direction: column;
  font-size: 58px;
  inline-size: 1519.2px;
  justify-content: center;
  line-height: 58px;
  position: relative;
  width: 1519.2px;
  color: rgb(255, 255, 255);
}
.vertical-slider-container2 {
  block-size: 160px;
  display: flex;
  flex-direction: column;
  font-family: Aeonik, Helvetica Neue, sans-serif;
  font-size: 58px;
  line-height: 58px;
  color: rgb(255, 255, 255);
}
.vertical-slider-text-container {
  block-size: 160px;
  display: flex;
  flex-direction: column;
  font-family: Aeonik, Helvetica Neue, sans-serif;
  font-size: 58px;
  line-height: 58px;
  color: rgb(255, 255, 255);
}
.vertical-slider-text-container-heading {
  block-size: 160px;
  font-size: 80px;
  font-weight: 300;
  line-height: 80px;
  margin: 0;
  max-width: 1045px;
  text-align: center;
  color: rgb(255, 255, 255);
}
.vertical-slider-text-container-heading-span {
  -webkit-background-clip: text;
  background-clip: text;
  background-image: linear-gradient(
    rgb(254, 208, 171) 20%,
    rgb(176, 173, 233) 100%
  );
  font-weight: 700;
  line-height: 80px;
  padding: 16px 0;
  text-align: center;
  color: rgba(0, 0, 0, 0);
}
.vertical-items-container {
  block-size: auto;
  box-sizing: border-box;
  font-family: Aeonik, Helvetica Neue, sans-serif;
  font-size: 58px;
  line-height: 58px;
  color: rgb(255, 255, 255);
  outline-color: rgb(255, 255, 255);
  text-decoration: none solid rgb(255, 255, 255);

  text-size-adjust: 100%;

  -webkit-text-fill-color: rgb(255, 255, 255);
  -webkit-text-stroke-color: rgb(255, 255, 255);
  width: 100%;
}
.vertical-items-container2 {
  block-size: 2186px;
  box-sizing: border-box;
  font-family: Aeonik, Helvetica Neue, sans-serif;
  font-size: 58px;
  line-height: 58px;
  color: rgb(255, 255, 255);
  outline-color: rgb(255, 255, 255);
  text-decoration: none solid rgb(255, 255, 255);
  text-size-adjust: 100%;
  -webkit-text-fill-color: rgb(255, 255, 255);
  -webkit-text-stroke-color: rgb(255, 255, 255);
}
.vertical-items-container-main {
  background-color: rgb(0, 0, 0);
  background: transparent linear-gradient(180deg, #9bb5fc 0%, #000000 100%) 0%
    0% no-repeat padding-box;
  block-size: 485px;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  bottom: 0px;
  box-sizing: border-box;
  color: rgb(255, 255, 255);
  display: flex;
  flex-wrap: wrap;
  font-family: Aeonik, Helvetica Neue, sans-serif;
  font-size: 58px;
  line-height: 58px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 82px;
  max-width: 1400px;
  outline-color: rgb(255, 255, 255);
  position: sticky;
  text-decoration: none solid rgb(255, 255, 255);
  text-decoration-color: rgb(255, 255, 255);
  text-emphasis-color: rgb(255, 255, 255);
  text-size-adjust: 100%;
  z-index: 4;
  -webkit-text-fill-color: rgb(255, 255, 255);
  -webkit-text-stroke-color: rgb(255, 255, 255);
  width: 88%;
  height: 485px;
}
.vertcical-item-text-container {
  block-size: 485px;
  border-bottom-color: rgb(255, 255, 255);
  box-sizing: border-box;
  color: rgb(255, 255, 255);
  flex-basis: 50%;
  font-family: Aeonik, Helvetica Neue, sans-serif;
  font-size: 58px;
  line-height: 58px;
  margin-left: 0;
  margin-right: 0;
  max-inline-size: 50%;
  max-width: 50%;
  outline-color: rgb(255, 255, 255);
  padding: 88px 0 0 160px;
  text-decoration: none solid rgb(255, 255, 255);
  text-emphasis-color: rgb(255, 255, 255);
  text-size-adjust: 100%;
  -webkit-text-fill-color: rgb(255, 255, 255);
  -webkit-text-stroke-color: rgb(255, 255, 255);
}
.vertcical-item-text-container-heading1 {
  block-size: 64px;
  border-bottom-color: rgb(215, 243, 246);
  border-top-color: rgb(215, 243, 246);
  box-sizing: border-box;
  color: rgb(215, 243, 246);
  font-family: var(--font-family);
  font-size: 40px;
  line-height: 64px;
  margin: 0;
  outline-color: rgb(215, 243, 246);
  text-decoration: none solid rgb(215, 243, 246);
  text-emphasis-color: rgb(215, 243, 246);
  text-size-adjust: 100%;
}
.vertcical-item-text-container-heading1-para1 {
  block-size: 120px;
  border-bottom-color: rgb(255, 255, 255);
  border-top-color: rgb(255, 255, 255);
  box-sizing: border-box;
  color: rgb(255, 255, 255);
  font-family: var(--font-family);
  font-size: 22px;
  line-height: 40px;
  margin: 24px 0 0 0;
  max-width: 450px;
  outline-color: rgb(255, 255, 255);
  text-decoration: none solid rgb(255, 255, 255);
  text-emphasis-color: rgb(255, 255, 255);
  text-size-adjust: 100%;
}
.vertcical-item-text-container-img1 {
  background-image: url(../../assets/Home/Mask\ Group\ 35.png);
  background-position: 50% 30px;
  background-repeat: no-repeat;
  block-size: 485px;
  box-sizing: border-box;
  caret-color: rgb(255, 255, 255);
  color: rgb(255, 255, 255);
  column-rule-color: rgb(255, 255, 255);
  flex-basis: 50%;
  font-size: 58px;
  inline-size: 668.438px;
  line-height: 58px;
  margin-left: 0;
  margin-right: 0;
  max-inline-size: 50%;
  max-width: 50%;
  min-block-size: auto;
  min-height: auto;
  min-inline-size: auto;
  min-width: auto;
  height: 100%;
}
.vertical-slide2-container {
  background: transparent linear-gradient(180deg, #9be2fc 0%, #000000 100%) 0%
    0% no-repeat padding-box;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  border-end-end-radius: 40px;
  border-end-start-radius: 40px;
  border-start-end-radius: 40px;
  border-start-start-radius: 40px;
  border-top-color: rgb(255, 255, 255);
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  bottom: 0px;
  box-sizing: border-box;
  caret-color: rgb(255, 255, 255);
  color: rgb(255, 255, 255);
  column-rule-color: rgb(255, 255, 255);
  display: flex;
  flex-wrap: wrap;
  font-family: Aeonik, Helvetica Neue, sans-serif;
  font-size: 58px;
  inline-size: 1336.89px;
  inset-block-end: 0px;
  line-height: 58px;
  margin-block-start: 82px;
  margin-inline-end: 91.1625px;
  margin-inline-start: 91.15px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 82px;
  max-inline-size: 1400px;
  max-width: 1400px;
  outline-color: rgb(255, 255, 255);
  position: sticky;
  text-decoration: none solid rgb(255, 255, 255);
  text-decoration-color: rgb(255, 255, 255);
  text-emphasis-color: rgb(255, 255, 255);
  text-size-adjust: 100%;
  z-index: 3;
  -webkit-text-fill-color: rgb(255, 255, 255);
  -webkit-text-stroke-color: rgb(255, 255, 255);
  width: 88%;
  height: 485px;
}
.vertcical-item-text-container-img2 {
  background-image: url(../../assets/Home/Mask\ Group\ 36.png);
  background-position: 50% 30px;
  background-repeat: no-repeat;
  block-size: 485px;
  box-sizing: border-box;
  caret-color: rgb(255, 255, 255);
  color: rgb(255, 255, 255);
  column-rule-color: rgb(255, 255, 255);
  flex-basis: 50%;
  font-size: 58px;
  inline-size: 668.438px;
  line-height: 58px;
  margin-left: 0;
  margin-right: 0;
  max-inline-size: 50%;
  max-width: 50%;
  min-block-size: auto;
  min-height: auto;
  min-inline-size: auto;
  min-width: auto;
  height: 100%;
}
.vertical-slide3-container {
  background: transparent linear-gradient(180deg, #9bfcc8 0%, #000000 100%) 0%
    0% no-repeat padding-box;
  block-size: 485px;
  border-block-end-color: rgb(255, 255, 255);
  border-block-start-color: rgb(255, 255, 255);
  border-bottom-color: rgb(255, 255, 255);
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  border-end-end-radius: 40px;
  border-end-start-radius: 40px;
  border-inline-end-color: rgb(255, 255, 255);
  border-inline-start-color: rgb(255, 255, 255);
  border-left-color: rgb(255, 255, 255);
  border-right-color: rgb(255, 255, 255);
  border-start-end-radius: 40px;
  border-start-start-radius: 40px;
  border-top-color: rgb(255, 255, 255);
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  bottom: 0px;
  box-sizing: border-box;
  caret-color: rgb(255, 255, 255);
  color: rgb(255, 255, 255);
  column-rule-color: rgb(255, 255, 255);
  display: flex;
  flex-wrap: wrap;
  font-family: Aeonik, Helvetica Neue, sans-serif;
  font-size: 58px;
  inline-size: 1336.89px;
  inset-block-end: 0px;
  line-height: 58px;
  margin-block-start: 82px;
  margin-inline-end: 91.1625px;
  margin-inline-start: 91.15px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 82px;
  max-inline-size: 1400px;
  max-width: 1400px;
  outline-color: rgb(255, 255, 255);
  position: sticky;
  text-decoration: none solid rgb(255, 255, 255);
  text-decoration-color: rgb(255, 255, 255);
  text-emphasis-color: rgb(255, 255, 255);
  text-size-adjust: 100%;
  z-index: 2;
  -webkit-text-fill-color: rgb(255, 255, 255);
  -webkit-text-stroke-color: rgb(255, 255, 255);
  width: 88%;
  height: 485px;
}
.vertcical-item-text-container-img3 {
  background-image: url(../../assets/Home/Mask\ Group\ 37.png);
  background-position: 50% 30px;
  background-repeat: no-repeat;
  block-size: 485px;
  box-sizing: border-box;
  caret-color: rgb(255, 255, 255);
  color: rgb(255, 255, 255);
  column-rule-color: rgb(255, 255, 255);
  flex-basis: 50%;
  font-size: 58px;
  inline-size: 668.438px;
  line-height: 58px;
  margin-left: 0;
  margin-right: 0;
  max-inline-size: 50%;
  max-width: 50%;
  min-block-size: auto;
  min-height: auto;
  min-inline-size: auto;
  min-width: auto;
  height: 100%;
}
.vertical-slide4-container {
  background-color: rgb(0, 0, 0);
  background-image: linear-gradient(
    rgb(104, 198, 245) -22.35%,
    rgba(104, 198, 245, 0.33) 47.17%,
    rgba(104, 198, 245, 0) 100%
  );
  block-size: 485px;
  border-block-end-color: rgb(255, 255, 255);
  border-block-start-color: rgb(255, 255, 255);
  border-bottom-color: rgb(255, 255, 255);
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  border-end-end-radius: 40px;
  border-end-start-radius: 40px;
  border-inline-end-color: rgb(255, 255, 255);
  border-inline-start-color: rgb(255, 255, 255);
  border-left-color: rgb(255, 255, 255);
  border-right-color: rgb(255, 255, 255);
  border-start-end-radius: 40px;
  border-start-start-radius: 40px;
  border-top-color: rgb(255, 255, 255);
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  bottom: 0px;
  box-sizing: border-box;
  caret-color: rgb(255, 255, 255);
  color: rgb(255, 255, 255);
  column-rule-color: rgb(255, 255, 255);
  display: flex;
  flex-wrap: wrap;
  font-family: Aeonik, Helvetica Neue, sans-serif;
  font-size: 58px;
  inline-size: 1336.89px;
  inset-block-end: 0px;
  line-height: 58px;
  margin-block-start: 82px;
  margin-inline-end: 91.1625px;
  margin-inline-start: 91.15px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 82px;
  max-inline-size: 1400px;
  max-width: 1400px;
  outline-color: rgb(255, 255, 255);
  position: sticky;
  text-decoration: none solid rgb(255, 255, 255);
  text-decoration-color: rgb(255, 255, 255);
  text-emphasis-color: rgb(255, 255, 255);
  text-size-adjust: 100%;
  z-index: 1;
  -webkit-text-fill-color: rgb(255, 255, 255);
  -webkit-text-stroke-color: rgb(255, 255, 255);
  width: 88%;
  height: 485px;
}
.vertcical-item-text-container-img4 {
  background-image: url(../../assets/Home/Mask\ Group\ 35.png);
  background-position: 50% 30px;
  background-repeat: no-repeat;
  block-size: 485px;
  box-sizing: border-box;
  caret-color: rgb(255, 255, 255);
  color: rgb(255, 255, 255);
  column-rule-color: rgb(255, 255, 255);
  flex-basis: 50%;
  font-size: 58px;
  inline-size: 668.438px;
  line-height: 58px;
  margin-left: 0;
  margin-right: 0;
  max-inline-size: 50%;
  max-width: 50%;
  min-block-size: auto;
  min-height: auto;
  min-inline-size: auto;
  min-width: auto;
  height: 100%;
}

/* Tablet styles */
@media (min-width: 768px) and (max-width: 1023px) {
  .vertical-slider-container,
  .vertical-slider-container2,
  .vertical-slider-text-container,
  .vertical-slider-text-container-heading,
  .vertical-slider-text-container-heading-span,
  .vertical-items-container,
  .vertical-items-container2,
  .vertical-items-container-main,
  .vertcical-item-text-container,
  .vertcical-item-text-container-heading1,
  .vertcical-item-text-container-heading1-para1,
  .vertcical-item-text-container-img1,
  .vertical-slide2-container,
  .vertcical-item-text-container-img2,
  .vertical-slide3-container,
  .vertcical-item-text-container-img3,
  .vertical-slide4-container,
  .vertcical-item-text-container-img4 {
    width: 100%; /* Fit to full width */
  }
  .vertcical-item-text-container-heading1 {
    block-size: 64px;
    border-bottom-color: rgb(215, 243, 246);
    border-top-color: rgb(215, 243, 246);
    box-sizing: border-box;
    color: rgb(215, 243, 246);
    font-family: var(--font-family);
    font-size: 28px;
    line-height: 38px;
    margin: 0;
    outline-color: rgb(215, 243, 246);
    text-decoration: none solid rgb(215, 243, 246);
    text-emphasis-color: rgb(215, 243, 246);
    text-size-adjust: 100%;
  }
  .vertcical-item-text-container-heading1-para1 {
    block-size: 200px;
    border-bottom-color: rgb(255, 255, 255);
    border-top-color: rgb(255, 255, 255);
    box-sizing: border-box;
    color: rgb(255, 255, 255);
    font-family: var(--font-family);
    font-size: 18px;
    line-height: 40px;
    margin: 24px 0 0 0;
    max-width: 500px;
    outline-color: rgb(255, 255, 255);
    text-decoration: none solid rgb(255, 255, 255);
    text-emphasis-color: rgb(255, 255, 255);
    text-size-adjust: 100%;
  }
  .vertcical-item-text-container-img1 {
    background-image: url(../../assets/Home/Mask\ Group\ 35.png);
    background-position: 50% 30px;
    background-repeat: no-repeat;
    block-size: 485px;
    box-sizing: border-box;
    caret-color: rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    column-rule-color: rgb(255, 255, 255);
    flex-basis: 50%;
    font-size: 58px;
    inline-size: 668.438px;
    line-height: 58px;
    margin-left: 0;
    margin-right: 0;
    max-inline-size: 50%;
    max-width: 50%;
    min-block-size: auto;
    min-height: auto;
    min-inline-size: auto;
    min-width: auto;
    height: 100%;
  }
  .vertcical-item-text-container {
    block-size: 485px;
    border-bottom-color: rgb(255, 255, 255);
    box-sizing: border-box;
    color: rgb(255, 255, 255);
    flex-basis: 50%;
    font-family: Aeonik, Helvetica Neue, sans-serif;
    font-size: 58px;
    line-height: 58px;
    margin-left: 0;
    margin-right: 0;
    max-inline-size: 50%;
    max-width: 50%;
    outline-color: rgb(255, 255, 255);
    padding: 60px 0 0 40px;
    text-decoration: none solid rgb(255, 255, 255);
    text-emphasis-color: rgb(255, 255, 255);
    text-size-adjust: 100%;
    -webkit-text-fill-color: rgb(255, 255, 255);
    -webkit-text-stroke-color: rgb(255, 255, 255);
  }
}
/* Large screen styles (Mac Air/Pro) */
@media (min-width: 1024px) {
  .vertical-slider-container,
  .vertical-slider-container2,
  .vertical-slider-text-container,
  .vertical-slider-text-container-heading,
  .vertical-slider-text-container-heading-span,
  .vertical-items-container,
  .vertical-items-container2,
  .vertical-items-container-main,
  .vertcical-item-text-container,
  .vertcical-item-text-container-heading1,
  .vertcical-item-text-container-heading1-para1,
  .vertcical-item-text-container-img1,
  .vertical-slide2-container,
  .vertcical-item-text-container-img2,
  .vertical-slide3-container,
  .vertcical-item-text-container-img3,
  .vertical-slide4-container,
  .vertcical-item-text-container-img4 {
    font-size: 58px;
    line-height: 58px;
    width: 100%; /* Fit to full width */
  }
  .vertcical-item-text-container-heading1 {
    font-size: 36px;
    line-height: 42px;
  }
  .vertcical-item-text-container-heading1-para1 {
    font-size: 24px;
    line-height: 40px;
  }

  .vertcical-item-text-container {
    padding: 60px 0 0 40px;
  }
}
/* Mobile styles */
@media (max-width: 768px) {
  .vertical-slider-container,
  .vertical-slider-container2,
  .vertical-slider-text-container,
  .vertical-slider-text-container-heading,
  .vertical-slider-text-container-heading-span,
  .vertical-items-container,
  .vertical-items-container2,
  .vertical-items-container-main,
  .vertcical-item-text-container,
  .vertcical-item-text-container-heading1,
  .vertcical-item-text-container-heading1-para1,
  .vertcical-item-text-container-img1,
  .vertical-slide2-container,
  .vertcical-item-text-container-img2,
  .vertical-slide3-container,
  .vertcical-item-text-container-img3,
  .vertical-slide4-container,
  .vertcical-item-text-container-img4 {
    width: 100%; /* Fit to full width */
  }
  .vertcical-item-text-container-heading1 {
    font-size: 28px;
    line-height: 38px;
  }
  .vertcical-item-text-container-heading1-para1 {
    font-size: 18px;
    line-height: 40px;
  }
}
