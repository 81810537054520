.list-hero-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.list-hero-container-img {
  max-width: 800px;
  max-height: 300px;
}
.list-hero-container h1 {
  background: transparent linear-gradient(180deg, #e0c3fc 0%, #8ec5fc 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
  font-size: 60px;
  padding: 40px 40px;
  font-family: var(--font-family);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
}
.list-hero-sub-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 600px;

}
.list-hero-sub-container img {
  height: 100px;
  width: 100px;
  margin-bottom: 20px;
  
}
.list-hero-sub-container h2 {
  text-align: left;
  font-size: 48px;
  letter-spacing: 0px;
  color: #ebebeb;
  opacity: 1;
  font-family: var(--font-family);
}
.list-hero-sub-container p {
  text-align: left;
  letter-spacing: 0px;
  color: #d5d5d5;
  opacity: 1;
  font-size: 20px;
  font-family: var(--font-family);
  margin-top: 10px;
}
.list-hero-sub-container button {
  width: 180px;
  height: 50px;
  background: #e8e8e8 0% 0% no-repeat padding-box;
  border-radius: 12px;
  opacity: 1;
  border-width: 0;
  margin-top: 40px;

}
.list-hero-sub-container span {
    width: 40px;
    height: 40px;
}
.list-hero-sub-container-img{
    height:160px;
    width:160px;
}
.list-hero-sub-container-img img{
    width: 100%;
    height: 100%;

}
.list-hero-sub-container-img img:hover{
    transform: scale(1.1);
    transition: all 0.5s ease-in-out;
}
.list-hero-sub-container-img2{
    max-height: 480px;
    max-width: 500px;
}

/* Mobile styles */
@media (max-width: 767px) {
  .list-hero-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .list-hero-container-img {
    max-width: 100%;
    max-height: 200px;
  }

  .list-hero-container h1 {
    font-size: 40px;
    padding: 20px 20px;
  }

  .list-hero-sub-container {
    align-items: center;
    text-align: center;
  }

  .list-hero-sub-container h2 {
    font-size: 36px;
  }

  .list-hero-sub-container p {
    font-size: 18px;
  }

  .list-hero-sub-container-img {
    height: 120px;
    width: 120px;
  }

  .list-hero-sub-container button {
    width: 160px;
    height: 45px;
    margin-top: 30px;
  }

  .list-hero-sub-container-img2 {
    max-height: 300px;
    max-width: 300px;
  }
}

/* Tablet styles */
@media (min-width: 768px) and (max-width: 1023px) {
  .list-hero-container-img {
    max-width: 400px;
    max-height: 250px;
  }

  .list-hero-container h1 {
    font-size: 48px;
    padding: 30px 30px;
  }

  .list-hero-sub-container h2 {
    font-size: 40px;
  }

  .list-hero-sub-container p {
    font-size: 20px;
  }

  .list-hero-sub-container-img {
    height: 150px;
    width: 150px;
  }

  .list-hero-sub-container button {
    width: 180px;
    height: 50px;
    margin-top: 35px;
  }

  .list-hero-sub-container-img2 {
    max-height: 400px;
    max-width: 400px;
  }
}

