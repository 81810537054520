.crowd-hero-conatiner {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: auto;
}

.crowd-hero-conatiner > div {
  margin: 10px;
}

.crowd-hero1-main-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.crowd-hero1-main-heading {
  font-size: 62px;
  font-family: var(--font-family);
  font-weight: 600;
  background: transparent linear-gradient(180deg, #ffecd2 0%, #fcb69f 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
  text-align: center;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  margin-top: 100px;
}
.crowd-hero1-main-para {
  font-size: 22px;
  text-align: center;
  font-family: var(--font-family);
  color: white;
  margin-top: 20px;
  max-width: 800px;
  justify-content: center;
  margin-bottom: 50px;
}
.crowd-hero1-maincontainer {
  width: 300px;
  height: 300px;
  border-radius: 18px;
  margin-bottom: 80px;
}
.crowd-hero-item-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 40px 20px;
}
.crowd-hero-item-container h1 {
  font-size: 42px;
  font-family: var(--font-family);
}
.crowd-hero-item-container img {
  height: 50px;
  width: 50px;
}
.crowd-hero1-item-para {
  font-size: 22px;
  font-family: var(--font-family);
  justify-content: center;
  padding-left: 20px;
}
.crowd-hero1-item-button {
  width: 250px;
  height: 40px;
  background: transparent linear-gradient(180deg, #222737 0%, #000000 100%) 0%
    0% no-repeat padding-box;
  border-radius: 7px;
  opacity: 1;
  color: white;
  margin-left: 20px;
  border-width: 0;
  margin-top: 20px;
}
.crowd-hero1-container1 {
  background: #d6ec70 0% 0% no-repeat padding-box;
  border-radius: 18px;
  opacity: 1;
}
.crowd-hero1-container2 {
  background: #efc656 0% 0% no-repeat padding-box;
  border-radius: 18px;
  opacity: 1;
}
.crowd-hero1-container3 {
  background: #e9f1f3 0% 0% no-repeat padding-box;
  border-radius: 18px;
  opacity: 1;
}

/* Mobile Version */
@media (max-width: 767px) {
  .crowd-hero1-main-heading {
    font-size: 32px;
    margin-top: 80px;
  }

  .crowd-hero1-main-para {
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 30px;
    max-width: 90%; /* Adjust the width for smaller screens */
  }

  .crowd-hero1-maincontainer {
    width: 300px;
    height: 220px;
    margin-bottom: 10px;
  }

  .crowd-hero-item-container h1 {
    font-size: 24px;
  }

  .crowd-hero-item-container img {
    height: 30px;
    width: 30px;
  }

  .crowd-hero1-item-para {
    font-size: 16px;
    padding-left: 10px;
  }

  .crowd-hero1-item-button {
    width:90%;
    height: 30px;
    font-size: 14px;
    margin-left: 10px;
    margin-top: 15px;
    font-family: var(--font-family);
  }
}

/* Tablet Version */
@media (min-width: 768px) and (max-width: 1023px) {
  .crowd-hero1-main-heading {
    font-size: 40px;
    margin-top: 100px;
  }

  .crowd-hero1-main-para {
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 50px;
  }

  .crowd-hero1-maincontainer {
    width: 250px;
    height: 250px;
    margin-bottom: 60px;
  }

  .crowd-hero-item-container h1 {
    font-size: 30px;
  }

  .crowd-hero-item-container img {
    height: 40px;
    width: 40px;
  }

  .crowd-hero1-item-para {
    font-size: 18px;
    padding-left: 20px;
  }

  .crowd-hero1-item-button {
    width: 100%;
    height: 35px;
    font-size: 16px;
    margin-left: 20px;
    margin-top: 20px;
  }
  
}
