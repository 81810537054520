.gpt3__brand {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px; /* Set a maximum width for the grid container */
  width: 100%;
  height: 200px;
  border-bottom: 1px solid #e5e5e5;
  background-color: #f5f5f5;
  background-blend-mode: color-burn;
  justify-items: center
}
.gpt3__brand div {
  width: 110px;
  height: 110px;
}

/* Styles for tablets (768px to 1023px wide) */
@media (min-width: 768px) and (max-width: 1023px) {
  .gpt3__brand div {
    width: 80px; /* Reduce the width for better spacing on tablets */
    height: 80px; /* Reduce the height for better spacing on tablets */
  }
}
/* Styles for mobile devices (up to 767px wide) */
@media (max-width: 767px) {
  .gpt3__brand {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px; /* Set a maximum width for the grid container */
    height: auto;
  }
  .gpt3__brand div {
    width: 60px; /* Reduce the width for better spacing on mobile */
    height: 60px; /* Reduce the height for better spacing on mobile */
  }
}
